import React, { useContext, useState, useEffect } from "react"
import { AppContext } from "../app"
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

// TODO: Cleanup
const Back = () => {
  // console.log("Back component running...");
  const { state, dispatch } = useContext(AppContext);
  const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex);
  // console.log("BACK COMPONENT: activeStepIndex: ", activeStepIndex);

  useEffect(() => {
    // console.log(`useEffect: activeStepIndex updated: `, activeStepIndex)
  }, [activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(`useEffect: state.userForm.activeStepIndex updated: `, state.userForm.activeStepIndex)
  }, [state.userForm.activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(`useEffect: state updated: `, state);
  }, [state]); // Only re-run the effect if activeStepIndex changes

  const _handleBack = (e) => {
    e.preventDefault();
    // console.log('event: e: ', e)
    setActiveStepIndex(activeStepIndex - 1)

    dispatch({
      type: "UPDATE_STEP",
      payload: activeStepIndex - 1,
    })
  }

  return (
    <>
    {/* <div>Back Component: activeStepIndex: {activeStepIndex}</div>
    <div>Back Component: state.userForm.activeStepIndex: {state.userForm.activeStepIndex}</div> */}
      { activeStepIndex !== 0 && (
        <>
          <button
            onClick={_handleBack}
            className="navButton prev me-2 me-sm-5 p-2"
          > <a href="#" className="nav-link">
            <HiOutlineArrowNarrowLeft className="leftArrowIcon" /> PREVIOUS</a>
          </button>



          {/* <button className="reverse me-5">
            <a href="#" className=" nav-link">
              PDF VERSION
            </a>
          </button> */}
        </>
      )}
    </>
  )
}

export default Back

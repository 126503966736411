import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../app";
import EnrollStart from "./enrollStart";
import EnrollStepOne from "./enrollStepOne";
import EnrollStepTwo from "./enrollStepTwo";
import EnrollStepThree from "./enrollStepThree";
import EnrollStepFour from "./enrollStepFour";
import loadable from '@loadable/component';
const EnrollStepFive = loadable(() => import('./enrollStepFive'));
// import EnrollStepFive from "./enrollStepFive";

const Step = () => {
  // console.log("STEP COMPONENT....")
  // shared context
  const { state, dispatch } = useContext(AppContext)

  const [activeStepIndex, setActiveStepIndex] = useState(
    state.userForm.activeStepIndex
  )

  // console.log("IN STEP COMPONENT: activeStepIndex: ", activeStepIndex)

  useEffect(() => {
    // console.log("useEffect: state updated: Enrollment Component...", state)
  }, [state])

  useEffect(() => {
    // console.log(`useEffect: activeStepIndex updated: `)
  }, [activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(
    //   `useEffect: state.userForm.activeStepIndex updated: `,
    //   state.userForm.activeStepIndex
    // )
  }, [state.userForm.activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  let stepContent;

  switch (state.userForm.activeStepIndex) {
    case 0:
      stepContent = <EnrollStart />
      break
    case 1:
      stepContent = <EnrollStepOne />
      break
    case 2:
      stepContent = <EnrollStepTwo />
      break
    case 3:
      stepContent = <EnrollStepThree />
      break
    case 4:
      stepContent = <EnrollStepFour />
      break
    case 5:
      stepContent = <EnrollStepFive />
      break
    default:
      break
  }

  return stepContent
}

export default Step;

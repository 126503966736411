import React, { useRef, useEffect, useState } from "react";

const ISISection = ({data, children}) => {
    const isiRef = useRef();
    const pinnedISIRef = useRef();

    const [ pinnedISIVisible, setPinnedISIVisible ] = useState(true);

    useEffect(() => {
        if (typeof window !== "undefined") {
            document.onscroll = () => {
                if (isiRef.current !== null && pinnedISIRef !== null) {
                    // console.log("isi: ", isiRef.current.getBoundingClientRect().y);
                    // console.log("pinned-isi: ", pinnedISIRef.current.getBoundingClientRect().y)
                    setPinnedISIVisible(isiRef.current.getBoundingClientRect().y > pinnedISIRef.current.getBoundingClientRect().y);
                }
            }
        }
    }, [])

    const clickLessButton = () => {
        if (typeof window !== "undefined") {
            window.scrollTo(50, 0);
        }
    }

    return (
        <>
            <section id="isi" ref={isiRef}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-none d-md-block position-relative">
                            <div className="less-button" onClick={clickLessButton}>
                                <div className="less-icon"></div>
                                <div className="less-text">LESS</div>
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </section>
            <section id="pinned-isi" ref={pinnedISIRef} className={pinnedISIVisible ? "visible" : "invisible"}>
                {children}
            </section>
        </>
    )
}


export default ISISection;

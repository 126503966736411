import React, { useState, useEffect, useContext } from "react"
import { AppContext } from "../app"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from "yup"
import CardContainer from "./cardContainer"
import StepContent from "./stepContent"
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName";
import { useAllImageQuery } from '../../hooks/useAllImageQuery'
// import * as html2canvas from "html2canvas";


const EnrollStepFour = () => {
  /////////////////////////////////////////////////////////////
  // shared context
  const { state, dispatch } = useContext(AppContext)
  // console.log("EnrollStepFour component: Global State/Context: ", state)

  const [product, setProduct] = useState(state.userForm.product)
  const [files, setFiles] = useState(state.userForm.formData.files)
  const [formData, setFormData] = useState(state.userForm.formData)
  const [activeStepIndex, setActiveStepIndex] = useState(
    state.userForm.activeStepIndex
  )

  const emailImageData = useAllImageQuery().allFile.edges // get all image data
  const emailImageName = `emailimage@2x` // name of image
  // console.log("emailImageName: ", emailImageName)

  // TODO: move to shared
  // toggle show add party
  const useToggle = (initialState) => {
    const [isToggled, setIsToggled] = React.useState(initialState)
    // put [setIsToggled] into the useCallback's dependencies array
    // this value never changes so the callback is not going to be ever re-created
    const toggle = React.useCallback(
      () => setIsToggled((state) => !state),
      [setIsToggled]
    )
    return [isToggled, toggle]
  }
  const [isToggled, toggle] = useToggle(false)

  const renderError = (message) => (
    <p className="error text-danger">{message}</p>
  )

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
  // End move to shared

  const ValidationSchema = yup.object().shape({
    consent: yup.boolean(),
    parent_guardian_extras: yup.string(),
    patient_signature_extras: yup.string(),
    parent_date_extras: yup.string(),
    patient_date_extras: yup.string(),
    // additonal info
    receive_information_extras: yup.boolean(),
    contact_me_extras: yup.boolean(),
    consider_research_extras: yup.boolean(),
    info_extra: yup.boolean(),
  })

  useEffect(() => {
    // console.log("useEffect: mount/load: EnrollStepFour Component...")
  }, [])

  useEffect(() => {
    // console.log(`useEffect: state updated: `, state)
  }, [state]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(`useEffect: activeStepIndex updated: `)
  }, [activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(
    //   "useEffect: state.userForm.product updated: ",
    //   state.userForm.product !== "" ? state.userForm.product : "EMPTY - default"
    // )
  }, [state.userForm.product])

  useEffect(() => {
    // console.log(
    //   "useEffect: product updated: ",
    //   product !== "" ? product : "EMPTY - default"
    // )
  }, [product])

  useEffect(() => {
    // console.log(
    //   "useEffect: files updated: ",
    //   files !== "" ? files : "Files currently EMPTY - default"
    // )
  }, [files])

  return (
    <div className={"enroll-content enroll-step-four " + state.userForm.product.toLowerCase()}>
      {/* <div>EnrollStepFour</div>
    <div>activeStepIndex: {activeStepIndex}</div>
    <div>state.userForm.activeStepIndex: {state.userForm.activeStepIndex}</div> */}

      <Formik
        initialValues={{
          consent: formData.consent ? formData.consent : "",
          patient_signature_extras: formData.patient_signature_extras
            ? formData.patient_signature_extras
            : "",
          patient_date_extras: formData.patient_date_extras ? formData.patient_date_extras : "",
          parent_date_extras: formData.parent_date_extras ? formData.parent_date_extras : "",
          parent_guardian_extras: formData.parent_guardian_extras
            ? formData.parent_guardian_extras
            : "",
          receive_information_extras: formData.receive_information_extras
            ? formData.receive_information_extras
            : false,
          contact_me_extras: formData.contact_me_extras
            ? formData.contact_me_extras
            : false,
          consider_research_extras: formData.consider_research_extras
            ? formData.consider_research_extras
            : false,
          info_extra: formData.info_extra
            ? formData.info_extra
            : false,
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values) => {
          const data = { ...formData, ...values }
          // console.log("Enroll Step 4: onSubmit: values: ", values)

          // see data
          // await sleep(500)
          // alert(JSON.stringify(values, null, 2))

          setFormData(data)

          // const input = document.querySelector("#pills-tabContent .enroll-content");
          // input.classList.add("generating-pdf")
          // html2canvas(input).then(canvas => {
          //     const imgData = canvas.toDataURL("image/png");
          //     dispatch({
          //         type: "UPDATE_PDF_IMAGE_STEP_FOUR",
          //         payload: imgData
          //     })
          //     input.classList.remove("generating-pdf")
          // });

          dispatch({
            type: "UPDATE_FORM_DATA",
            payload: data,
          })
          setActiveStepIndex(activeStepIndex + 1)

          dispatch({
            type: "UPDATE_STEP",
            payload: activeStepIndex + 1,
          })
        }}
      >
        <Form className="enrollment-form">
          <div
            className={`tab-pane fade ${product && "show active"}`}
            id={`pills-${product}`}
            role="tabpanel"
            aria-labelledby={`pills-${product}-tab`}
            key={product + activeStepIndex}
          >
            {activeStepIndex !== 0 ? (
              <>
                <CardContainer key={product + activeStepIndex}>
                  {/* <div>BEGIN CARD CONTAINER CONTENT!!</div> */}
                  <StepContent>
                    {/* form step */}
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-8 left-content">
                          {/* Main Form Content area */}

                          <div className="container">
                            {/* checkboxes extras */}
                            <div className="about-you row mb-3">
                              <div className="mb-3 col-sm-12">
                                Almost there—can we send you some extras?
                              </div>
                            </div>
                            <div className="row mb-2 ms-1">
                              <div className="mb-2 col-sm-12 form-check">
                              <Field
                                    type="checkbox"
                                    name="receive_information_extras"
                                    className="check me-2 form-check-input"
                                  />
                                <label className="form-check-label">

                                  I would like to receive information about
                                  Ultragenyx educational events, newsletters,
                                  and resources
                                </label>
                              </div>
                            </div>
                            <div className="row mb-2 ms-1">
                              <div className="mb-2 col-sm-12 form-check">
                              <Field
                                    type="checkbox"
                                    name="contact_me_extras"
                                    className="check me-2 form-check-input"
                                  />
                                <label className="form-check-label">
                                  Please contact me so that I can learn more
                                  about UltraCare patient services
                                </label>
                              </div>
                            </div>
                            <div className="row mb-2 ms-1">
                              <div className="mb-2 col-sm-12 form-check">
                                <Field
                                  type="checkbox"
                                  name="consider_research_extras"
                                  className="check me-2 form-check-input"
                                />
                                <label className="form-check-label">
                                  Please consider me for Ultragenyx market research projects and contact me with details
                                </label>
                              </div>
                            </div>
                            {state.userForm.product !== "Mepsevii" && (
                              <div className="row mb-2 ms-1">
                                <div className="mb-2 col-sm-12 form-check">
                                  <Field
                                    type="checkbox"
                                    name="info_extra"
                                    className="check me-2 form-check-input"
                                  />
                                  <label className="form-check-label">
                                    I am interested in information about
                                    {state.userForm.product === "Dojolvi"
                                      ? ` long-chain fatty acid oxidation disorders `
                                      : ` X-linked hypophosphatemia (XLH) or tumor-induced osteomalacia (TIO)`}
                                  </label>
                                </div>
                              </div>
                            )}

                            <div className="row mb-3">
                              <div className="mb-3 col-md-8">
                                <label
                                  htmlFor="patient_signature_extras"
                                  className="form-label"
                                >
                                  Patient Signature
                                </label>
                                <Field
                                  name="patient_signature_extras"
                                  className="form-control"
                                  placeholder=""
                                  title="Signature can only contain alphabet letters."
                                  pattern="^[a-zA-Z\- ]+$"
                                  id="patient_signature_extras"
                                />
                                <div className="pdf-text">{formData.patient_signature_extras}</div>
                              </div>

                              <div className="mb-3 col-md-3">
                                <label
                                  htmlFor="patient_date_extras"
                                  className="form-label"
                                >
                                  Date
                                </label>
                                <Field
                                  name="patient_date_extras"
                                  className="form-control"
                                  placeholder=""
                                  title="YYYY/MM/DD"
                                  pattern="^[1-9][0-9]{3}/[01][0-9]/[0-3][0-9]$"
                                  id="patient_date_extras"
                                />
                                <div className="pdf-text">{formData.patient_date_extras}</div>
                              </div>
                            </div>

                            {/* Parent / Guardina */}
                            <div className="row mb-3">
                              <div className="mb-3 col-md-8">
                                <label
                                  htmlFor="parent_guardian_extras"
                                  className="form-label"
                                >
                                  Parent/Guardian Signature (if patient is a
                                  minor)
                                </label>
                                <Field
                                  name="parent_guardian_extras"
                                  className="form-control"
                                  placeholder=""
                                  title="Signature can only contain alphabet letters."
                                  pattern="^[a-zA-Z\- ]+$"
                                  id="parent_guardian_extras"
                                />
                                <div className="pdf-text">{formData.parent_guardian_extras}</div>
                              </div>
                              <div className="mb-3 col-md-3">
                                <label htmlFor="parent_date_extras" className="form-label">
                                  Date
                                </label>
                                <Field
                                  name="parent_date_extras"
                                  className="form-control"
                                  placeholder=""
                                  title="YYYY/MM/DD"
                                  pattern="^[1-9][0-9]{3}/[01][0-9]/[0-3][0-9]$"
                                  id="parent_date_extras"
                                />
                                <div className="pdf-text">{formData.parent_date_extras}</div>
                              </div>
                            </div>
                            <div className="row mb-3 mb-lg-0">
                              <div className="mb-3 mb-lg-0">
                                <p className="about-you next-step mb-1">
                                  The next step is to be completed by your
                                  healthcare provider.
                                </p>
                                <p className="next-steps mb-lg-0">
                                  <strong>
                                    This information will not be saved.
                                  </strong>{" "}
                                  If you are not with your healthcare provider, download this
                                  completed section and share with your healthcare provider
                                  at your next visit.{" "}
                                  <strong>
                                    Successful enrollment requires both patient
                                    and healthcare provider completion.
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* END MAIN FORM Content area */}
                        </div>

                        {/* SIDE CONTENT START */}
                        <div
                          className={`col-12 col-lg-4 p-4 ${
                            state.userForm.product &&
                            state.userForm.product != ""
                              ? String(state.userForm.product.toLowerCase()) +
                                "-side-bg-gradient"
                              : ""
                          }`}
                        >
                          {/* SIDE CONTENT - PASSED form step */}
                          {/* make component */}
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12 p-0 text-center email-image">
                                {/* side */}
                                {activeStepIndex === 4 ? (
                                  /* email image */
                                  <GatsbyImage
                                    image={findImageByName(
                                      emailImageData,
                                      emailImageName
                                    )}
                                    alt={`email logo`}
                                    className={"emailImage"}
                                  />
                                ) : null}
                                <div className="download-text text-center mt-3">
                                  Download the completed patient portion of the
                                  UltraCare enrollment Start Form
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* END PASSED form step */}
                        </div>
                      </div>
                    </div>
                    {/* end form step */}
                  </StepContent>
                  {/* <div>END CARD CONTAINER CONTENT!!</div> */}
                </CardContainer>
              </>
            ) : null}
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default EnrollStepFour

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useResourceThumbnailImageQuery } from '../hooks/useResourceThumbnailImageQuery';
import { findImageByName } from "./findImageByName";
import parse from "html-react-parser";

const ResourceCard = ({data}) => {
    const resThumbData = useResourceThumbnailImageQuery().allFile.edges;
    // console.log(data);

    const getButtonText = (data) => {
        if (data.resourceCustomFields.resourceType === "pdf") {
            return "DOWNLOAD";
        }
        if (data.resourceCustomFields.resourceType === "site") {
            let domain = new URL(data.resourceCustomFields.resourcePath);
            return "VISIT " + domain.hostname.replace("www.", "");
        }
    }

    return (
        <div className="row res-row">
            {
                data.map((resData, i) => (
                    <div key={i} className="col res-col">
                        <a href={resData.resourceCustomFields.resourcePath} target="_blank">
                            <div className={"card h-100 text-center res-card " + resData.resourceCustomFields.resourceType}>
                                <div className="card-body">
                                    <div>
                                        <h5 className="card-title">{parse(resData.resourceCustomFields.resourceTitle)}</h5>
                                        <p className="card-text">{parse(resData.resourceCustomFields.resourceDescription)}</p>
                                    </div>
                                    <div className="res-thumb">
                                        <GatsbyImage image={findImageByName(resThumbData, resData.resourceCustomFields.resourceThumb)} alt={"Thumbnail of " + resData.resourceCustomFields.resourceTitle}/>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button>{getButtonText(resData)}</button>
                                </div>
                            </div>
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

export default ResourceCard;

import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../app";
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName";
import { useAllImageQuery } from '../../hooks/useAllImageQuery'
import Stepper from "./stepper";
import Back from "./back";
import Next from "./next";


// TODO cleanup
// export const renderError = (message) => (
//   <p className=" text-danger">{message}</p>
// )

// export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
// // End move to shared

// export const _handleFormSubmit = (values) => {
//     const data = { ...formData, ...values }
//     // see data
//     sleep(500);
//     alert(JSON.stringify(values, null, 2));

//     setFormData(data)
//     dispatch({
//        type: "UPDATE_FORM_DATA",
//        payload: data
//      });
//     setActiveStepIndex(activeStepIndex + 1)

//     dispatch({
//        type: "UPDATE_STEP",
//        payload: activeStepIndex + 1
//      });
//   }

// TODO: Cleanup
// Creates a Form Step content section
const StepContent = ({children}) => {

  // console.log("StepContent component...");
  const { state, dispatch } = useContext(AppContext);

  // initial local state
  const [product, setProduct] = useState(state.userForm.product);
  const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex);

  const logoImageData = useAllImageQuery().allFile.edges; // get all image data
  const logoImageName = String(state.userForm.product.toLowerCase()) + `-logo@2x`; // name of image
  // console.log("logoImageName: ", logoImageName);

  return (
    <>
      {/* dynamic className for gradient */}
      {/* card body */}
      {/* <div>START STEP CONTENT!!</div> */}
      <div
        className={`card-body ${
          state.userForm.product && state.userForm.product != ""
            ? String(state.userForm.product.toLowerCase()) + "1-bg-gradient"
            : ""
        }`}
      >

        {/* FORM STEP CONTENT!!! */}
        {children}

      </div>
      {/* <div>END STEP CONTENT!!</div> */}
      {/* end card body */}
    </>
  )
}

  export default StepContent;

import React from "react";
import ResourceCard from "./resource-card";
import { useResourceUltraCareQuery } from '../hooks/useResourceUltraCareQuery';
// import { useResourceXLHQuery } from '../hooks/useResourceXLHQuery';
// import { useResourceFAODQuery } from '../hooks/useResourceFAODQuery';
// import { useResourceMPSVIIQuery } from '../hooks/useResourceMPSVIIQuery';
import { useResourceDiseaseEducationQuery } from '../hooks/useResourceDiseaseEducationQuery';


const Resources = ({data}) => {
    const ultraCareData = useResourceUltraCareQuery().allWpResource.nodes;
    // const xlhData = useResourceXLHQuery().allWpResource.nodes;
    // const faodData = useResourceFAODQuery().allWpResource.nodes;
    // const mpsviiData = useResourceMPSVIIQuery().allWpResource.nodes;
    const diseaseEdData = useResourceDiseaseEducationQuery().allWpResource.nodes;

    const resTabs = [
        {
            title: "UltraCare Resources",
            slug: "ultracare",
            data: ultraCareData
        },
        // {
        //     title: "XLH Resources",
        //     slug: "xlh",
        //     data: xlhData
        // },
        // {
        //     title: "LC-FAOD Resources",
        //     slug: "lc-faod",
        //     data: faodData
        // },
        // {
        //     title: "MPS VII Resources",
        //     slug: "mps-vii",
        //     data: mpsviiData
        // },
        {
            title: "Disease Education Resources",
            slug: "disease-ed",
            data: diseaseEdData
        }
    ]

    return (
        <section id="resources-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav>
                            <div className="nav nav-tabs nav-pills nav-justified resources-tabs" id="res-tab" role="tablist">
                                {
                                    resTabs.map((resTab, i) => (
                                        <button
                                            key={i}
                                            className={`nav-link ${i === 0 ? "active" : ""}`}
                                            id={"res-" + resTab.slug + "-tab"}
                                            data-bs-toggle="tab"
                                            data-bs-target={"#res-" + resTab.slug}
                                            type="button"
                                            role="tab"
                                            aria-controls={"res-" + resTab.slug}
                                            aria-selected={i === 0}
                                        ><span>{resTab.title}</span></button>
                                    ))
                                }
                            </div>
                        </nav>

                        <div className="tab-content" id="res-tab-content">
                            {
                                resTabs.map((resTab, i) => (
                                    <div
                                        key={i}
                                        className={`tab-pane fade ${i === 0 ? "show active" : ""}`}
                                        id={"res-" + resTab.slug}
                                        role="tabpanel"
                                        aria-labelledby={"res-" + resTab.slug + "-tab"}
                                    >
                                        <ResourceCard data={resTab.data}/>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="resources-container">
                        {/*ultraCareData.map((resourceData, i) => (
                            <p key={i}><a href={resourceData.resourceCustomFields.resourcePath} target="_blank">{resourceData.resourceCustomFields.resourceTitle}</a></p>
                        ))*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Resources;

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../app";

function Stepper() {
  // shared context
  const { state, dispatch } = useContext(AppContext)

  const [activeStepIndex, setActiveStepIndex] = useState(
    state.userForm.activeStepIndex
  )
  const [product] = useState(state.userForm.product)
  const [formData] = useState(state.userForm.formData)

  useEffect(() => {
    // console.log("Initial mount/load: Stepper Component")
  }, [])

  useEffect(() => {
    // console.log(`useEffect: state updated: `, state);
  }, [state]); // Only re-run the effect if activeStepIndex changes

//   useEffect(() => {
//     console.log(`useEffect: activeStepIndex updated: `)
//   }, [activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(`useEffect: state.userForm.activeStepIndex updated: `, state.userForm.activeStepIndex);
  }, [state.userForm.activeStepIndex]); // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    const stepperItems = document.querySelectorAll(".stepper-item")

    // console.log("Stepper Component: activeStepIndex updated: ", activeStepIndex)
    // console.log("Stepper Component: formData updated: ", formData)
    // console.log("Stepper Component: product: ", product)

    stepperItems.forEach((step, i) => {
      if (i <= activeStepIndex -1 ) {
        step.classList.add("current-step", "text-white")
      } else {
        step.classList.remove("current-step", "text-white")
      }
    })
  }, [activeStepIndex])

  return (
    <>
      {/* <div>Stepper Component: activeStepIndex: {activeStepIndex}</div>
      <div>Stepper Component: state.userForm.activeStepIndex: {state.userForm.activeStepIndex}</div> */}

      <div className="w-75 w-sm-50 p-3 d-flex mx-auto justify-content-around align-items-center">
        <div className="stepper-item text-center rounded-circle ">
          &nbsp;
        </div>
        <div className="step-divider"></div>
        <div className="stepper-item text-center rounded-circle ">
         &nbsp;
        </div>
        <div className="step-divider"></div>
        <div className="stepper-item text-center rounded-circle ">
         &nbsp;
        </div>
        <div className="step-divider"></div>
        <div className="stepper-item text-center rounded-circle ">
         &nbsp;
        </div>
        <div className="step-divider"></div>
        <div className="stepper-item text-center rounded-circle ">
         &nbsp;
        </div>
      </div>
    </>
  )
}

export default Stepper;

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useResourceUltraCareQuery = () => {
    const data = useStaticQuery(graphql `
    {
        allWpResource (
            sort: {fields: [resourceCustomFields___resourceOrder], order: ASC}
            filter: {tags: {nodes: {elemMatch: {slug: {eq: "ultracare"}}}}}
        ) {
            nodes {
                resourceCustomFields {
                    resourceTitle
                    resourceDescription
                    resourcePath
                    resourceThumb
                    resourceOrder
                    resourceType
                }
                tags {
                    nodes {
                        slug
                    }
                }
            }
        }
    }
  `)
    return data
}

import React, { useState, useEffect } from "react";
import { AppContext } from "./app"
import { useMetaDataQuery } from '../hooks/useSiteMetaData'
import { useAllImageQuery } from '../hooks/useAllImageQuery'
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { findImageByName } from "./findImageByName";
import ContactInfo from "./contactinfo";
import Enrollment from "./enrollment";
import TeamSection from "./team";
import Resources from "./resources";
import Social from "./social";
import ISISection from "./isi";
import StayInformed from "./stayinformed";

import parse, {domToReact, attributesToProps} from "html-react-parser"
import SEO from "./seo"

const MainContent = ({data, props}) => {
    // shared context
    const {state, dispatch} = React.useContext(AppContext);
    // console.log('Global State/Context: ', state);
    // page data
    // console.log('page data: ', data);

    // page data
    const {title, link, content, slug } = data.wpPage;

    // page mdx content
    const pageMdx = data.pageMdx;

    // Site metadata
    const siteMetadata = useMetaDataQuery();
    // image path
    const {imagePath} = siteMetadata.site.siteMetadata;

    // seo
    // const seoData = data.seodata;

    // page custom config
    const configData = JSON.parse(data.config.customConfiguration.config);
    // console.log("configData: ", configData)
    // console.log("configData.heroImage: ", configData.heroImage ? `YES -> Hero Image: ${configData.heroImage} ` : "NO Hero Image");

    const imageData = useAllImageQuery().allFile.edges; // get all image data

    // parser options
    const options = {
      replace: ({ attribs, children }) => {

        if (!attribs) {
          return;
        }

        if (typeof attribs.class !== "undefined" && attribs.class.indexOf("parse-image") >= 0) {
            return (
                // assuming image id will be image name + -image
                <GatsbyImage image={findImageByName(imageData, attribs.id.substring(0, attribs.id.length - 6))} alt={attribs.alt}/>
            )
        }

        if (typeof attribs.class !== "undefined" && attribs.class.indexOf("contact-info-card") >= 0) {
            return (
                <ContactInfo data={attribs.class}/>
            )
        }

        // Enrollment component
        if (attribs.id === 'enroll-now') {
          return (
            <Enrollment/>
          )
        }

        if (typeof attribs.class !== "undefined" && attribs.class.indexOf("team-section") >= 0) {
            return (
                <TeamSection data={attribs}>
                    { domToReact(children, options) }
                </TeamSection>
            )
        }

        if (attribs.id === 'resources-section') {
            return (
                <Resources />
            )
        }

        if (attribs.class === 'social-share-component') {
            return (
                <Social/>
            )
        }

        if (attribs.id === 'isi') {
            return (
                <ISISection>
                    { domToReact(children, options) }
                </ISISection>
            )
        }

        if (attribs.class === 'home-hero') {
          return (
            <div style={{border: "2px solid #ffcc00"}}>
              {domToReact(children, options)}
            </div>
          );
        }
      }
    };

    return (
      <div className={"container-fluid body-container main " + slug}>
        <SEO data={siteMetadata} seoData={configData} />
        <div className="row">
          <main className="col-sm-12">
            {/* main content */}
            {parse(content, options)}
            <StayInformed data={slug}/>
          </main>
        </div>
      </div>
    );
  }

  export default MainContent;

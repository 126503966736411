import React, { useState, useEffect, useContext } from "react";
import { Link } from 'gatsby'
import { AppContext } from "../app";
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName";
import { useAllImageQuery } from '../../hooks/useAllImageQuery'
import Stepper from "./stepper";
import PDFButton from "./pdfButton";
import Back from "./back";
import Next from "./next";
import { showTabs } from "../utils/utils"

// TODO: Cleanup
// Creates a Card body with a product selection
const CardContainer = ({children}) => {
  // console.log("CardContainer component...");
  const { state, dispatch } = useContext(AppContext);
  const tabsVisible = showTabs();
  // console.log('tabsVisible: ', tabsVisible );

  // initial local state
  const [product, setProduct] = useState(state.userForm.product);
  const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex);

  // if crysvita, special logo handling as there are alternate versions: large round & smaller horizontal;
  const isCrysvita = (state.userForm.product.toLowerCase() === 'crysvita');
  const logoImageData = useAllImageQuery().allFile.edges; // get all image data
  const logoImageName = String(state.userForm.product.toLowerCase()) + (isCrysvita ? `-small-logo@2x`:`-logo@2x`); // name of image
  // console.log("logoImageName: ", logoImageName);

  const handImageData = useAllImageQuery().allFile.edges; // get all image data
  const handImageName = `hand-wave-copy-4-${String(state.userForm.product.toLowerCase())}@3x`; // name of image
  // console.log("handImageName: ", handImageName);

  const papersHeaderBlueImageData = useAllImageQuery().allFile.edges // get all image data
  const papersHeaderBlueImageName = `papers-header-${String(state.userForm.product.toLowerCase())}@3x` // name of image
  // console.log("papersHeaderBlueImageName: ", papersHeaderBlueImageName)

  const signatureHeaderImageData = useAllImageQuery().allFile.edges; // get all image data
  const signatureHeaderImageName = `signature-${String(state.userForm.product.toLowerCase())}@3x`; // name of image
  // console.log("signatureHeaderImageName: ", signatureHeaderImageName);

  const mailHeaderImageData = useAllImageQuery().allFile.edges; // get all image data
  const mailHeaderImageName = `mail-icon-${String(state.userForm.product.toLowerCase())}@3x`; // name of image
  // console.log("mailHeaderImageName: ", mailHeaderImageName);

  const clapHeaderImageData = useAllImageQuery().allFile.edges; // get all image data
  const clapHeaderImageName = `clap-${String(state.userForm.product.toLowerCase())}@3x`; // name of image
  // console.log("clapHeaderImageName: ", clapHeaderImageName);

  // download image
  const downloadImageData = useAllImageQuery().allFile.edges; // get all image data
  const downloadImageName = `download-icon@2x`; // name of image
  // console.log("downloadImageName: ", downloadImageName);

  const logoAltText = {
      crysvita: "CRYSVITA (burosumab-twza) logo",
      dojolvi: "DOJOLVI (triheptanoin) logo",
      mepsevii: "Mepsevii (vestronidase alfa-vjbk) logo"
  }

  return (
    <>
      {/* <div>START CARD CONTAINER!!</div> */}
      {/* card container component */}
      <div
        className={`card ${
          product && product != ""
            ? String(state.userForm.product.toLowerCase()) + "-selected"
            : ""
        } ${state.userForm.activeStepIndex > 0 ? "step-up" : ""} ${tabsVisible ? "" : "no-tabs"} shadow-sm h-75 enroll-section text-center`}
      >
        {/* CARD CONTAINER !!! */}
        <div
          className={`card-header pt-4 ${
            state.userForm.product && state.userForm.product != ""
              ? String(product.toLowerCase())
              : ""
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-2 d-none d-md-block">
                {activeStepIndex !== 0 ? (
                  /* logo */
                  <GatsbyImage
                    image={findImageByName(logoImageData, logoImageName)}
                    alt={logoAltText[state.userForm.product.toLowerCase()]}
                    className={"logoHeaderImage"}
                  />
                ) : null}
              </div>

              <div className="start-header col-12 col-md-8">
                <span className="start-header-text">{state.userForm.product} Ultracare Start Form</span>
                {activeStepIndex !== 0 ? <Stepper /> : null}
              </div>

              <div className="col-12 col-md-2 pe-4 pt-2 right-icon d-none d-md-flex">
                {activeStepIndex === 1 ? (
                  /* hand image */
                  <GatsbyImage
                    image={findImageByName(handImageData, handImageName)}
                    alt={`hand wave image`}
                    className={"handHeaderImage"}
                  />
                ) : null}

                {activeStepIndex === 2 ? (
                  /* papers image */
                  <GatsbyImage
                    image={findImageByName(
                      papersHeaderBlueImageData,
                      papersHeaderBlueImageName
                    )}
                    alt={`papers image`}
                    className={"papersBlueHeaderImage"}
                  />
                ) : null}

                {activeStepIndex === 3 ? (
                  /* signature image */
                  <GatsbyImage
                    image={findImageByName(
                      signatureHeaderImageData,
                      signatureHeaderImageName
                    )}
                    alt={`signature image`}
                    className={"signatureHeaderImage"}
                  />
                ) : null}

                {activeStepIndex === 4 ? (
                  /* mail image */
                  <GatsbyImage
                    image={findImageByName(
                      mailHeaderImageData,
                      mailHeaderImageName
                    )}
                    alt={`mail image`}
                    className={"mailHeaderImage"}
                  />
                ) : null}

                {activeStepIndex === 5 ? (
                  /* clap image */
                  <GatsbyImage
                    image={findImageByName(
                      clapHeaderImageData,
                      clapHeaderImageName
                    )}
                    alt={`clap image`}
                    className={"clapHeaderImage"}
                  />
                ) : null}

              </div>
            </div>
          </div>
        </div>

        {/* pass in content */}
        {children}

        {/* Enrollment footer */}
        <div
          className={`card-footer pb-3 d-flex ${
            activeStepIndex === 0
              ? "justify-content-center"
              : "justify-content-between step-up"
          }`}
        >
          {activeStepIndex === 0 && (
              <PDFButton />
          )}

          <Back />

          {activeStepIndex === 0 && <Next label="GET STARTED" />}

          {activeStepIndex > 0 && activeStepIndex < 4 && (
            <button className="navButton next mb-2 ps-3 pe-3 p-2" type="submit">
              {/*STEP:{activeStepIndex} - */} NEXT
            </button>
          )}

          {activeStepIndex === 4 && (
            <button className="navButton next mb-2 ps-3 pe-3 p-2" type="submit">
              {/*STEP:{activeStepIndex} - */} DOWNLOAD
                  <GatsbyImage
                    image={findImageByName(
                      downloadImageData,
                      downloadImageName
                    )}
                    alt={`download icon image`}
                    className={"downloadImage ms-1 mt-1"}
                  />
            </button>
          )}

          {activeStepIndex === 5 && (
            <button className="navButton next last-step mb-2 ps-3 pe-3 p-2" type="submit">
              <Link to="/patient-services/" activeClassName="active" className="nav-link">GO TO PATIENT SERVICES</Link>
              {/* <Link to={item.url} activeClassName="active" className="nav-link">
                {item.label}
              </Link> */}
            </button>
          )}


        </div>
        {/* End Enrollment footer */}
      </div>
      {/* <div>END CARD CONTAINER!!</div> */}

      {/* end card container component */}
    </>
  )
}

  export default CardContainer;

import { Link } from "gatsby"
import React, { useContext, useState, useEffect } from "react"
import { AppContext } from "../app"
// import * as html2canvas from "html2canvas";

const Next = ({label}) => {
  // console.log("Next component running...")

  const { state, dispatch } = useContext(AppContext)
  const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex)
  // console.log("IN NEXT COMPONENT: activeStepIndex: ", activeStepIndex)

  useEffect(() => {
    // console.log(`useEffect: state.userForm.activeStepIndex updated: `, state.userForm.activeStepIndex);
  }, [state.userForm.activeStepIndex]); // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(`useEffect: activeStepIndex updated: `, activeStepIndex);
  }, [activeStepIndex]); // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(`useEffect: state updated: `, state);
  }, [state]); // Only re-run the effect if activeStepIndex changes

  const _handleNext = (e) => {
    e.preventDefault();
    // console.log('event: e: ', e)
    // console.log('_handleNext called, updating activeStepIndex');

    // if (activeStepIndex === 0) {
    //     const input = document.querySelector("#pills-tabContent .enroll-content");
    //     input.classList.add("generating-pdf")
    //     html2canvas(input).then(canvas => {
    //         const imgData = canvas.toDataURL("image/png");
    //         dispatch({
    //             type: "UPDATE_PDF_IMAGE_STEP_ZERO",
    //             payload: imgData
    //         })

    //         input.classList.remove("generating-pdf")
    //     });
    // }

    setActiveStepIndex(activeStepIndex + 1);

    dispatch({
      type: "UPDATE_STEP",
      payload: activeStepIndex + 1
    });
  }

  return (
    <>

      {/* <div>Next Component: activeStepIndex: {activeStepIndex}</div>
      <div>Next Component: state.userForm.activeStepIndex: {state.userForm.activeStepIndex}</div> */}

      {activeStepIndex === 0 && (
        <>
          <button
            onClick={_handleNext}
            className="me-sm-5 p-2"
          >
            <a href="#" className="nav-link">
            { label ? label : "NEXT" }
            </a>
          </button>
        </>
      )}
    </>
  )
}

export default Next

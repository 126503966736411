import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useUCGQuery = () => {
    const data = useStaticQuery(graphql `
    {
        allWpTeamMember(
            filter: {tags: {nodes: {elemMatch: {slug: {eq: "ucg"}}}}},
            sort: {fields: [teamMemberCustomFields___memberFirstname], order: ASC}
        ) {
            nodes {
                teamMemberCustomFields {
                    memberFirstname
                    memberLastname
                    memberBios
                    memberQuote
                }
                tags {
                    nodes {
                        slug
                        name
                        description
                    }
                }
            }
        }
    }
  `)
    return data
}

import React, { useState, useEffect, useContext } from "react"
import { AppContext } from "../app"
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from "yup"
import Card from "./card"
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName"
import { useAllImageQuery } from "../../hooks/useAllImageQuery"

import useToggle from "../../hooks/useToggle"

const AddParty = ({pdfRenderData}) => {
  // console.log("AddParty component called...")
  // shared context
  const { state, dispatch } = useContext(AppContext)

  const addPartyImageData = useAllImageQuery().allFile.edges // get all image data
  const addPartyImageName = `add-party-${String(state.userForm.product.toLowerCase()) }@2x` // name of image
  // console.log("addPartyImageName: ", addPartyImageName)

  const [isToggled, toggle] = useToggle(false)


  // console.log("AddParty component: Global State/Context: ", state)
  const [activeStepIndex, setActiveStepIndex] = useState(
    state.userForm.activeStepIndex
  )

  return (
    <>
      {/* make component */}
      <div className="container add-a-party">
        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="add-party d-flex justify-content-between p-4 ps-2 ps-sm-4 ps-lg-2 ps-xl-4 pe-2 pe-sm-4 pe-lg-2 pe-xl-4">
              {activeStepIndex === 3 ? (
                /* add party image */
                <GatsbyImage
                  image={findImageByName(addPartyImageData, addPartyImageName)}
                  alt={`add party image`}
                  className={"addPartyImage"}
                />
              ) : null}
              <span className="add-p text-start">Add a Party</span>{" "}
              <span
                className="addPlus d-flex justify-content-center"
                onClick={toggle}
              >
                {isToggled ? "-" : "+"}
              </span>
            </div>

            {
              isToggled && (
                <div className="party d-flex justify-content-between mt-1 p-1">
                  <div className="container">
                    {/* add party component */}

                    {/* row */}
                    <div className="row mb-2">
                      <div className="mb-1 col-md-12">
                        <label htmlFor="addPartyFullName" className="mb-0 form-label">
                          First and Last Name
                        </label>
                        <Field
                          name="addPartyFullName"
                          className="form-control"
                          placeholder=""
                          title="Name can only contain alphabet letters."
                          pattern="^[a-zA-Z\- ]+$"
                          id="addPartyFullName"
                        />
                        <div className="pdf-text">{pdfRenderData.addPartyFullName}</div>
                      </div>
                    </div>

                    {/* row */}
                    <div className="row p-0 mb-0">
                      <div className="mb-1 pe-0 col-md-7">
                        <label
                          htmlFor="addPartyRelationship"
                          className="mb-0 form-label"
                        >
                          Relationship to Patient
                        </label>
                        <Field
                          name="addPartyRelationship"
                          className="form-control"
                          placeholder=""
                          pattern="^[a-zA-Z\-' ]+$"
                          id="addPartyRelationship"
                        />
                        <div className="pdf-text">{pdfRenderData.addPartyRelationship}</div>
                      </div>
                      {/* party type */}
                      <div className="mb-1 pe-0 col-md-5">
                        <div id="checkbox-group"></div>
                        <div role="group" aria-labelledby="checkbox-group">
                          <label>
                            <Field
                              type="radio"
                              name="addPartyType"
                              value="primary"
                              className="check me-1 neutral-color"
                            />
                            Primary
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="addPartyType"
                              value="secondary"
                              className="check me-1 neutral-color"
                            />
                            Secondary
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="addPartyType"
                              value="tertiary"
                              className="check me-1 neutral-color"
                            />
                            Tertiary
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="mb-1 col-md-12">
                        <label
                          htmlFor="addPartyStreetAddress"
                          className="mb-0 form-label"
                        >
                          Street Address
                        </label>
                        <Field
                          name="addPartyStreetAddress"
                          className="form-control"
                          placeholder=""
                          id="addPartyStreetAddress"
                        />
                        <div className="pdf-text">{pdfRenderData.addPartyStreetAddress}</div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="mb-1 col-md-5">
                        <label htmlFor="addPartyCity" className="mb-0 form-label">
                          City
                        </label>
                        <Field
                          name="addPartyCity"
                          className="form-control"
                          placeholder=""
                          pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                          title="Please enter a valid city."
                          id="addPartyCity"
                        />
                        <div className="pdf-text">{pdfRenderData.addPartyCity}</div>
                      </div>

                      <div className="mb-1 col-md-3">
                        <label htmlFor="addPartyState" className="mb-0 form-label">
                          State
                        </label>
                        <Field
                          name="addPartyState"
                          className="form-control"
                          placeholder=""
                          pattern="^[a-zA-Z ]+$"
                          title="Please enter a valid state."
                          id="addPartyState"
                        />
                        <div className="pdf-text">{pdfRenderData.addPartyState}</div>
                      </div>

                      <div className="mb-1 col-md-4">
                        <label htmlFor="addPartyZip" className="mb-0 form-label">
                          Zip
                        </label>
                        <Field
                          name="addPartyZip"
                          className="form-control"
                          placeholder=""
                          pattern="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
                          title="Please enter a valid zipcode"
                          id="addPartyZip"
                        />
                        <div className="pdf-text">{pdfRenderData.addPartyZip}</div>
                      </div>
                    </div>

                    {/* row */}
                    <div className="row mb-2">
                      <div className="mb-1 col-md-7">
                        <label htmlFor="addPartyPhone" className="mb-0 form-label">
                          Phone Number
                        </label>
                        <Field
                          name="addPartyPhone"
                          className="form-control"
                          placeholder="(123)-456-0000"
                          pattern="\(?[0-9]{3}\)?-?[0-9]{3}-?[0-9]{4}"
                          id="addPartyPhone"
                        />
                        <div className="pdf-text">{pdfRenderData.addPartyPhone}</div>
                      </div>
                    </div>

                    {/* row */}
                    {/* Party Disclose Info */}
                    <div className="row mb-1">
                      <div
                        className="col-sm-1"
                        aria-labelledby="addPartyDisclose"
                      >
                        <label htmlFor="addPartyDisclose" className="form-label">
                          <Field
                            type="checkbox"
                            name="addPartyDisclose"
                            className="check me-2"
                          />
                        </label>
                      </div>
                      <div className="col-sm-11 permission">
                        I give permission to the Patient Support team to
                        disclose my patient case information to the party listed
                        above.
                      </div>
                    </div>
                  </div>

                  {/* <AddParty /> */}
                  {/* end Add Party component */}
                </div>
              )
              /* Add party image */
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AddParty

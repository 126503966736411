import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../app";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import Card from "./card"
import useToggle from "../../hooks/useToggle";
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName";
import { useAllImageQuery } from '../../hooks/useAllImageQuery'
import CardContainer from "./cardContainer";
import CardContent from "./cardContent";
import StepContent from "./stepContent";
import Back from "./back";
import Next from "./next";
// import * as html2canvas from "html2canvas";

// TODO: Cleanup

const EnrollStepOne = () => {

    // console.log("EnrollStepOne component...")

    /////////////////////////////////////////////////////////////
    // shared context
    const {state, dispatch} = useContext(AppContext);
    // console.log('EnrollStepOne component: Global State/Context: ', state);

    const [caregivers, setCaregivers] = useState([
      {
        name: "",
        relationship: "",
        phone: "",
        email: "",
        leave_message: "",
      },
    ])

    const [product, setProduct] = useState(state.userForm.product)
    const [formData, setFormData] = useState(state.userForm.formData)
    const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex);

    const careImageData = useAllImageQuery().allFile.edges; // get all image data
    const careImageName = `care-icon-${String(state.userForm.product.toLowerCase())}@3x`; // name of image
    // console.log("careImageName: ", careImageName);

    // TODO: Move to shared
    const _handleFormSubmit = () => {
        // console.log('_handleFormSubmit called...')
    }

    const [isToggled, toggle] = useToggle(false);
    const [isPhoneToggled, togglePhone] = useToggle(false)

    const renderError = (message) => (
      <p className="error text-danger">{message}</p>
    )

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    // End move to shared

    const today = new Date();

    const ValidationSchema = yup.object().shape({
      name: yup.string().matches(/^[a-zA-Z\- ]+$/, {message: "Name can only contain alphabet letters."}).required("First name is a required field."),
      middle: yup.string().matches(/^[a-zA-Z\- ]+$/, {message: "Alphabet letters only."}),
      last: yup.string().matches(/^[a-zA-Z\- ]+$/, {message: "Name can only contain alphabet letters."}).required("Last name is a required field."),

      // date of birth
      dob_month: yup.string().matches(/^[0-9]{2}?$/, {message: " "}),
      dob_day: yup.string().matches(/^[0-9]{2}$/, {message: " "}),
      dob_year: yup.string().matches(/^[0-9]{4}$/, {message: " "}),

      // ssn
      ssn_last_four: yup.string().matches(/^[0-9]{4}$/, {message: " "}),

      address: yup.string(),
      city: yup.string().matches(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/, {message: "Please enter a valid city."}),
      state: yup.string().matches(/^[a-zA-Z ]+$/, {message: "Please enter a valid state."}),
      zip: yup.string(),

      time_to_contact: yup.string().matches(/^[0-1][0-9]:[0-5][0-9]$/, {message: " "}),

      // email
      email: yup.string().email("Please enter a valid email.").required("Email is a required field."),
    })

    useEffect(() => {
      // console.log('useEffect: mount/load: EnrollStepOne Component...');
    }, []);

    useEffect(() => {
        // console.log(`useEffect: state updated: `, state);
      }, [state]); // Only re-run the effect if activeStepIndex changes

    useEffect(() => {
        // console.log(`useEffect: activeStepIndex updated: `)
      }, [activeStepIndex]) // Only re-run the effect if activeStepIndex changes

    useEffect(() => {
      // console.log('useEffect: state.userForm.product updated: ', state.userForm.product !== "" ? state.userForm.product : "EMPTY - default");
    }, [state.userForm.product]);

    useEffect(() => {
      // console.log('useEffect: product updated: ', product !== "" ? product : "EMPTY - default" );
    }, [product]);

    useEffect(() => {
      // console.log('useEffect: caregivers updated: ', caregivers !== [] ? caregivers : "EMPTY - caregivers array" );
    }, [caregivers]);

    const AddCaregiver = () => {
      return (
        <div className="container">
          { caregivers.map( (careGiverObject, index) =>
            <>
            {/* <pre>{JSON.stringify(item, null, 4)}</pre> */}
              <div key={index}>Name: {careGiverObject.name} at index: {index}</div>
              <div key={index}>Relationship: {careGiverObject.relationship} at index: {index}</div>
              <div key={index}>Phone: {careGiverObject.phone} at index: {index}</div>
              <div key={index}>Email: {careGiverObject.email} at index: {index}</div>
              <div key={index}>Leave Message: {careGiverObject.leave_message} at index: {index}</div>
            </>
          )}
        </div>
      )
    }

  return (
    <div className={"enroll-content enroll-step-one " + state.userForm.product.toLowerCase()}>
      {/* <div>EnrollStepOne</div> */}
      {/* <div>activeStepIndex: {activeStepIndex}</div> */}
      {/*<div>state.userForm.activeStepIndex: {state.userForm.activeStepIndex}</div> */}
      <Formik
        initialValues={{
          name: formData.name ? formData.name : "",
          middle: formData.middle ? formData.middle : "",
          last: formData.last ? formData.last : "",
          email: formData.email ? formData.email : "",
          gender: formData.gender ? formData.gender : "",
          dob_month: formData.dob_month ? formData.dob_month : "",
          dob_day: formData.dob_day ? formData.dob_day : "",
          dob_year: formData.dob_year ? formData.dob_year : "",
          ssn_last_four: formData.ssn_last_four ? formData.ssn_last_four : "",
          address: formData.address ? formData.address : "",
          city: formData.city ? formData.city : "",
          state: formData.state ? formData.state : "",
          zip: formData.zip ? formData.zip : "",

          // phone 1
          phoneLocation: formData.phoneLocation ? formData.phoneLocation : "",
          phone: formData.phone ? formData.phone : "",
          time_to_contact: formData.time_to_contact
            ? formData.time_to_contact
            : "",
          time_select: formData.time_select ? formData.time_select : "",

          // phone 2
          phoneLocation2: formData.phoneLocation2
            ? formData.phoneLocation2
            : "",
          phone2: formData.phone2 ? formData.phone2 : "",
          time_to_contact2: formData.time_to_contact2
            ? formData.time_to_contact2
            : "",
          time_select2: formData.time_select2 ? formData.time_select2 : "",

          contact: formData.contact ? formData.contact : "",
          language: formData.language ? formData.language : "",
          caregiverFullName: formData.caregiverFullName
            ? formData.caregiverFullName
            : "",
          caregiverRelationship: formData.caregiverRelationship
            ? formData.caregiverRelationship
            : "",
          caregiverPhone: formData.caregiverPhone
            ? formData.caregiverPhone
            : "",
          caregiverEmail: formData.caregiverEmail
            ? formData.caregiverEmail
            : "",
          caregiverLeaveMessage: formData.caregiverLeaveMessage
            ? formData.caregiverLeaveMessage
            : "",
          // added multiple - dynamic options
          phones: [
            {
              phoneLocation: "",
              phone: "",
              time_to_contact: "",
              time_select: "",
            },
          ],
          caregivers: [
            {
              caregiverFullName: formData.caregiverFullName
                ? formData.caregiverFullName
                : "",
              caregiverRelationship: formData.caregiverRelationship
                ? formData.caregiverRelationship
                : "",
              caregiverPhone: formData.caregiverPhone
                ? formData.caregiverPhone
                : "",
              caregiverEmail: formData.caregiverEmail
                ? formData.caregiverEmail
                : "",
              caregiverLeaveMessage: formData.caregiverLeaveMessage
                ? formData.caregiverLeaveMessage
                : "",
            },
          ],
          // end multiple add
        }}
        validationSchema={ValidationSchema}
        onSubmit={async (values) => {
          const data = { ...formData, ...values }
          // see data
          // await sleep(500)
          // alert(JSON.stringify(values, null, 2))

          setFormData(data)

          // const input = document.querySelector("#pills-tabContent .enroll-content");
          // input.classList.add("generating-pdf")
          // html2canvas(input).then(canvas => {
          //     const imgData = canvas.toDataURL("image/png");
          //     dispatch({
          //         type: "UPDATE_PDF_IMAGE_STEP_ONE",
          //         payload: imgData
          //     })

          //     input.classList.remove("generating-pdf")
          // });

          dispatch({
            type: "UPDATE_FORM_DATA",
            payload: data,
          })
          setActiveStepIndex(activeStepIndex + 1)

          dispatch({
            type: "UPDATE_STEP",
            payload: activeStepIndex + 1,
          })
        }}
      >
        <Form className="enrollment-form">
          <div
            className={`tab-pane fade ${product && "show active"}`}
            id={`pills-${product}`}
            role="tabpanel"
            aria-labelledby={`pills-${product}-tab`}
            key={product + activeStepIndex}
          >
            {activeStepIndex !== 0 ? (
              <>
                {/* <div>WE ARE STEP ONE!! - {state.userForm.product}</div> */}
                <CardContainer key={product + activeStepIndex}>
                  {/* <div>BEGIN CARD CONTAINER CONTENT!!</div> */}
                  <StepContent>
                    {/* form step */}
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-8 left-content step-one">
                          {/* Main Form Content */}
                          {/* <StepOneForm /> */}

                          <div className="about-you mb-3">
                            First, tell us about you
                          </div>

                          <div className="container">
                            <div className="row mb-0">
                              <div className="mb-3 col-md-5 col-lg-4 col-xl-5">
                                <label htmlFor="firstName" className="form-label">
                                  First Name*
                                </label>
                                <Field
                                  name="name"
                                  className="form-control"
                                  placeholder=""
                                  id="firstName"
                                />
                                <ErrorMessage
                                  name="name"
                                  render={renderError}
                                />
                                <div className="pdf-text">{formData.name}</div>
                              </div>

                              <div className="mb-3 col-md-2 col-lg-3 col-xl-2">
                                <label htmlFor="middle" className="form-label">
                                  Middle initial
                                </label>
                                <Field
                                  name="middle"
                                  className="form-control"
                                  placeholder=""
                                  id="middle"
                                />
                                <ErrorMessage
                                  name="middle"
                                  render={renderError}
                                />
                                <div className="pdf-text">{formData.middle}</div>
                              </div>

                              <div className="mb-3 col-md-5">
                                <label htmlFor="lastName" className="form-label">
                                  Last Name*
                                </label>
                                <Field
                                  name="last"
                                  className="form-control"
                                  placeholder=""
                                  id="lastName"
                                />
                                <ErrorMessage
                                  name="last"
                                  render={renderError}
                                />
                                <div className="pdf-text">{formData.last}</div>
                              </div>
                            </div>

                            <div className="row mb-0">
                              <div className="mb-3 col-12 col-md-4 col-xl-3">
                                {/* checkboxes male / female */}
                                <div id="checkbox-group"></div>
                                <div
                                  role="group"
                                  aria-labelledby="checkbox-group"
                                >
                                  <label>
                                    <Field
                                      type="radio"
                                      name="gender"
                                      value="male"
                                      className="check me-2"
                                    />
                                    Male
                                  </label>
                                  <label>
                                    <Field
                                      type="radio"
                                      name="gender"
                                      value="female"
                                      className="check ms-3 me-2"
                                    />
                                    Female
                                  </label>
                                </div>
                              </div>

                              <div className="mb-3 col-12 col-md-6 col-xl-5">
                                {/* date of birth fields */}
                                <div
                                  role="group"
                                  aria-labelledby="checkbox-dob"
                                >
                                  <label htmlFor="dob" className="form-label">
                                    Date of Birth
                                    {/*<Field
                                      type=""
                                      name="dob_month"
                                      placeholder="MM"
                                      pattern="[0-9]{2}"
                                      className="form-control dob_month ms-1"
                                    />*/}
                                    <Field
                                      as="select"
                                      name="dob_month"
                                      className="form-control like-text dob_month ms-1"
                                    >
                                        <option value="">MM</option>
                                        {(() => {
                                            let options = [];
                                            for (let i = 1; i <= 12; i++) {
                                                let month = "00" + i;
                                                month = month.substr(month.length-2);
                                                options.push(<option value={month} key={"month-"+i}>{month}</option>);
                                            }
                                            return options;
                                        })()}
                                    </Field>
                                  </label>
                                  <div className="pdf-text form-control dob_month ms-1">{formData.dob_month}</div>
                                  <label htmlFor="dob" className="form-label">
                                    {/*<Field
                                      type=""
                                      name="dob_day"
                                      placeholder="DD"
                                      pattern="[0-9]{2}"
                                      className="form-control dob_day ms-1"
                                    />*/}
                                    <Field
                                      as="select"
                                      name="dob_day"
                                      className="form-control like-text dob_day ms-1"
                                    >
                                        <option value="">DD</option>
                                        {(() => {
                                            let options = [];
                                            for (let i = 1; i <= 31; i++) {
                                                let day = "00" + i;
                                                day = day.substr(day.length-2);
                                                options.push(<option value={day} key={"day-"+i}>{day}</option>);
                                            }
                                            return options;
                                        })()}
                                    </Field>
                                  </label>
                                  <div className="pdf-text form-control dob_day ms-1">{formData.dob_day}</div>
                                  <label htmlFor="dob" className="form-label">
                                    {/*<Field
                                      type=""
                                      name="dob_year"
                                      placeholder="YYYY"
                                      pattern="[0-9]{4}"
                                      className="form-control dob_year ms-1"
                                    />*/}
                                    <Field
                                      as="select"
                                      name="dob_year"
                                      className="form-control like-text dob_year ms-1"
                                    >
                                        <option value="">YYYY</option>
                                        {(() => {
                                            let options = [];
                                            for (let i = today.getFullYear(); i >= 1900; i--) {
                                                options.push(<option value={i} key={"year-"+i}>{i}</option>);
                                            }
                                            return options;
                                        })()}
                                    </Field>
                                  </label>
                                  <div className="pdf-text form-control dob_year ms-1">{formData.dob_year}</div>
                                </div>
                              </div>

                              <div className="mb-3 col-12 col-md-12 col-xl-4">
                                <label htmlFor="ssn" className="form-label">
                                  Last 4 Digits of SSN
                                </label>
                                <Field
                                  name="ssn_last_four"
                                  type=""
                                  className="form-control ms-1 ssn_last_four"
                                  placeholder="••••"
                                  pattern="[0-9]{4}"
                                  id="ssn"
                                />
                                <div className="pdf-text form-control ms-1 ssn_last_four ms-1">{formData.ssn_last_four}</div>
                              </div>
                            </div>

                            <div className="row mb-0">
                              <div className="mb-3 col-12 col-md-5">
                                <label
                                  htmlFor="streetAddress"
                                  className="form-label"
                                >
                                  Street Address
                                </label>
                                <Field
                                  name="address"
                                  className="form-control"
                                  placeholder=""
                                  id="streetAddress"
                                />
                                <div className="pdf-text">{formData.address}</div>
                              </div>
                            </div>

                            <div className="row mb-0">
                              <div className="mb-3 col-12 col-md-5">
                                <label htmlFor="city" className="form-label">
                                  City
                                </label>
                                <Field
                                  name="city"
                                  className="form-control"
                                  placeholder=""
                                  id="city"
                                />
                                <ErrorMessage
                                  name="city"
                                  render={renderError}
                                />
                                <div className="pdf-text">{formData.city}</div>
                              </div>

                              <div className="mb-3 col-6 col-md-3">
                                <label htmlFor="state" className="form-label">
                                  State
                                </label>
                                <Field
                                  name="state"
                                  className="form-control"
                                  placeholder=""
                                  id="state"
                                />
                                <ErrorMessage
                                  name="state"
                                  render={renderError}
                                />
                                <div className="pdf-text">{formData.state}</div>
                              </div>

                              <div className="mb-3 col-6 col-md-2">
                                <label htmlFor="zip" className="form-label">
                                  Zip
                                </label>
                                <Field
                                  name="zip"
                                  className="form-control"
                                  placeholder=""
                                  pattern="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
                                  title="Please enter a valid zipcode"
                                  id="zip"
                                />
                                <div className="pdf-text">{formData.zip}</div>
                              </div>
                            </div>

                            {/* phone / contact time */}
                            <div className="row mb-0">
                              <div className="mb-3 col-4 col-md-2">
                                <label
                                  htmlFor="phoneLocation"
                                  className="form-label"
                                >
                                  Phone Number
                                </label>
                                <Field
                                  as="select"
                                  name="phoneLocation"
                                  className="form-control form-select phoneLocation px-2 pt-0 pb-0"
                                  placeholder=""
                                  id="phoneLocation"
                                >
                                  <option value="home">Home</option>
                                  <option value="work">Work</option>
                                  <option value="mobile">Mobile</option>
                                </Field>
                                <div className="downcarret">⌄</div>
                              </div>
                              <div className="mb-3 col-8 col-md-3">
                                <label htmlFor="phone" className="form-label">
                                  &nbsp;
                                </label>
                                <Field
                                  name="phone"
                                  className="form-control phone"
                                  placeholder="(123)-456-0000"
                                  pattern="\(?[0-9]{3}\)?-?[0-9]{3}-?[0-9]{4}"
                                  id="phone"
                                />
                                <div className="pdf-text">{formData.phone}</div>
                              </div>

                              <div className="mb-3 col-6 col-md-2">
                                {/* best time to contact */}
                                <div
                                  role="group"
                                  aria-labelledby="time_to_contact"
                                >
                                  <label
                                    htmlFor="time_to_contact"
                                    className="form-label"
                                  >
                                    Best Time to Contact
                                  </label>
                                  {/*<Field
                                    type=""
                                    name="time_to_contact"
                                    placeholder="00:00"
                                    pattern="[0-1][0-9]:[0-5][0-9]"
                                    className="form-control time_to_contact ms-1"
                                  />*/}
                                  <Field
                                    as="select"
                                    name="time_to_contact"
                                    className="form-control like-text time_to_contact ms-1"
                                  >
                                      <option value="">00:00</option>
                                      {(() => {
                                          let options = [];
                                          for (let i = 1; i <= 12; i++) {
                                              let time = "00" + i;
                                              time = time.substr(time.length-2);
                                              options.push(<option value={time + ":00"} key={i+":00"}>{time + ":00"}</option>);
                                              options.push(<option value={time + ":30"} key={i+":30"}>{time + ":30"}</option>);
                                          }
                                          return options;
                                      })()}
                                  </Field>
                                  <div className="pdf-text form-control ms-1">{formData.time_to_contact}</div>
                                </div>
                              </div>
                              <div
                                className="mb-3 col-6 col-md-2"
                                aria-labelledby="time_select"
                              >
                                {/* AM / PM Select */}
                                <label htmlFor="time_select" className="form-label">
                                  &nbsp;
                                </label>
                                <Field
                                  as="select"
                                  name="time_select"
                                  className="form-control form-select time_select px-2 pt-0 pb-0"
                                  placeholder=""
                                  id="time_select"
                                >
                                  <option value="am">AM</option>
                                  <option value="pm">PM</option>
                                </Field>
                                <div className="downcarret">⌄</div>
                              </div>
                            </div>

                            {/* add another phone number */}
                            <div className="row mb-0" id="addPhoneRow">
                              <div className="mb-0">
                                {isPhoneToggled ? (
                                  ""
                                ) : (
                                  <>
                                    <span
                                      className="addPlus phone me-2"
                                      onClick={togglePhone}
                                    >
                                      {isPhoneToggled ? "-" : "+"}
                                    </span>
                                    <label
                                      htmlFor="addPhone"
                                      className="form-label"
                                    >
                                      {isPhoneToggled
                                        ? ""
                                        : "Add Another Phone Number"}
                                    </label>
                                  </>
                                )}
                              </div>

                              {/* <div
                                className="addPlus d-flex justify-content-center"
                                onClick={togglePhone}
                              >
                                {isPhoneToggled ? "-" : "+"}
                              </div> */}
                              {isPhoneToggled && (
                                <>
                                  {/* <div>We are TOGGLED: {togglePhone}</div> */}
                                  <div className="mb-3 col-4 col-md-2">
                                    <span
                                      className="addPlus phone me-2"
                                      onClick={togglePhone}
                                    >
                                      {isPhoneToggled ? "-" : "+"}
                                    </span>
                                    <label
                                      htmlFor="phoneLocation2"
                                      className="form-label"
                                      style={{width: "10px"}}
                                    >
                                      Secondary Phone Number
                                    </label>
                                    <Field
                                      as="select"
                                      name="phoneLocation2"
                                      className="form-control form-select phoneLocation px-2 pt-0 pb-0"
                                      placeholder=""
                                      id="phoneLocation2"
                                    >
                                      <option value="home">Home</option>
                                      <option value="work">Work</option>
                                      <option value="mobile">Mobile</option>
                                    </Field>
                                    <div className="downcarret">⌄</div>
                                  </div>
                                  <div className="mb-3 col-8 col-md-3">
                                    <label htmlFor="phone2" className="form-label">
                                      &nbsp;
                                    </label>
                                    <Field
                                      name="phone2"
                                      className="form-control phone"
                                      placeholder="(123)-456-0000"
                                      pattern="\(?[0-9]{3}\)?-?[0-9]{3}-?[0-9]{4}"
                                      id="phone2"
                                    />
                                    <div className="pdf-text">{formData.phone2}</div>
                                  </div>

                                  <div className="mb-3 col-6 col-md-2">
                                    {/* best time to contact */}
                                    <div
                                      role="group"
                                      aria-labelledby="time_to_contact2"
                                    >
                                      <label
                                        htmlFor="time_to_contact2"
                                        className="form-label"
                                      >
                                        Best Time to Contact
                                      </label>
                                      {/*<Field
                                        type=""
                                        name="time_to_contact2"
                                        placeholder="00:00"
                                        pattern="[0-1][0-9]:[0-5][0-9]"
                                        className="form-control time_to_contact ms-1"
                                      />*/}
                                      <Field
                                        as="select"
                                        name="time_to_contact2"
                                        className="form-control like-text time_to_contact ms-1"
                                      >
                                          <option value="">00:00</option>
                                          {(() => {
                                              let options = [];
                                              for (let i = 1; i <= 12; i++) {
                                                  let time = "00" + i;
                                                  time = time.substr(time.length-2);
                                                  options.push(<option value={time + ":00"} key={i+":00"}>{time + ":00"}</option>);
                                                  options.push(<option value={time + ":30"} key={i+":30"}>{time + ":30"}</option>);
                                              }
                                              return options;
                                          })()}
                                      </Field>
                                      <div className="pdf-text form-control ms-1">{formData.time_to_contact2}</div>
                                    </div>
                                  </div>
                                  <div
                                    className="mb-3 col-6 col-md-2"
                                    aria-labelledby="time_select2"
                                  >
                                    {/* AM / PM Select */}
                                    <label
                                      htmlFor="time_select2"
                                      className="form-label"
                                    >
                                      &nbsp;
                                    </label>
                                    <Field
                                      as="select"
                                      name="time_select2"
                                      className="form-control form-select time_select px-2 pt-0 pb-0"
                                      placeholder=""
                                      id="time_select2"
                                    >
                                      <option value="am">AM</option>
                                      <option value="pm">PM</option>
                                    </Field>
                                    <div className="downcarret">⌄</div>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="row mb-0 ">
                              <div className="mb-3 col-md-4">
                                <label
                                  htmlFor="emailAddress"
                                  className="form-label"
                                >
                                  Email Address*
                                </label>
                                <Field
                                  name="email"
                                  className="form-control"
                                  placeholder=""
                                  id="emailAddress"
                                />
                                <ErrorMessage
                                  name="email"
                                  render={renderError}
                                />
                                <div className="pdf-text">{formData.email}</div>
                              </div>

                              {/* preferred language */}
                              <div className="mb-3 col-md-3">
                                <label htmlFor="language" className="form-label">
                                  Preferred language
                                </label>
                                <Field
                                  as="select"
                                  name="language"
                                  className="form-control form-select language px-2 pt-0 pb-0"
                                  placeholder=""
                                  id="language"
                                >
                                  <option value="english">English</option>
                                  <option value="spanish">Spanish</option>
                                  <option value="mandarin">Mandarin</option>
                                  <option value="vietnamese">Vietnamese</option>
                                  <option value="tagalog">Tagalog</option>
                                  <option value="french">French</option>
                                  <option value="german">German</option>
                                  <option value="italian">Italian</option>
                                  <option value="portuguese">Portuguese</option>
                                </Field>
                                <div className="downcarret">⌄</div>
                              </div>
                              {/* preferred contact */}
                              <div className="mb-3 col-md-4">
                                <label htmlFor="contact" className="form-label">
                                  Preferred Method of Contact
                                </label>
                                <Field
                                  as="select"
                                  name="contact"
                                  className="form-control form-select contact px-2 pt-0 pb-0"
                                  placeholder=""
                                  id="contact"
                                >
                                  <option value="phone">Phone</option>
                                  <option value="text">Text</option>
                                  <option value="email">Email</option>
                                </Field>
                                <div className="downcarret">⌄</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* SIDE CONTENT START */}
                        <div
                          className={`col-12 col-lg-4 p-4 ${
                            state.userForm.product &&
                            state.userForm.product != ""
                              ? String(state.userForm.product.toLowerCase()) +
                                "-side-bg-gradient"
                              : ""
                          }`}
                        >
                          {/* SIDE CONTENT - PASSED form step */}
                          {/* make component */}
                          <div className="container care-giver">
                            <div className="row">
                              <div className="col-sm-12 p-0">
                                <div className="add-caregiver d-flex justify-content-between p-4 ps-2 ps-sm-4 pe-2 pe-sm-4">
                                  {activeStepIndex === 1 ? (
                                    /* care image */
                                    <GatsbyImage
                                      image={findImageByName(
                                        careImageData,
                                        careImageName
                                      )}
                                      alt={`hand wave logo`}
                                      className={"careImage"}
                                    />
                                  ) : null}
                                  <span className="add-care">
                                    Add a Caregiver
                                  </span>{" "}
                                  <span
                                    className="addPlus d-flex justify-content-center"
                                    onClick={toggle}
                                  >
                                    {isToggled ? "-" : "+"}
                                  </span>
                                </div>

                                {
                                  isToggled && (
                                    <div className="caregiver d-flex justify-content-between mt-1 p-3">
                                      <div className="container">
                                        {/* add caregiver component */}

                                        {/* row */}
                                        <div className="row mb-3">
                                          <div className="mb-3 col-md-12">
                                            <label
                                              htmlFor="caregiverFullName"
                                              className="form-label"
                                            >
                                              Caregiver First and Last Name
                                            </label>
                                            <Field
                                              name="caregiverFullName"
                                              className="form-control"
                                              placeholder=""
                                              title="Name can only contain alphabet letters."
                                              pattern="^[a-zA-Z\- ]+$"
                                              id="caregiverFullName"
                                            />
                                            <div className="pdf-text">{formData.caregiverFullName}</div>
                                          </div>
                                        </div>

                                        {/* row */}
                                        <div className="row mb-3">
                                          <div className="mb-3 col-md-12">
                                            <label
                                              htmlFor="caregiverRelationship"
                                              className="form-label"
                                            >
                                              Relationship to Patient
                                            </label>
                                            <Field
                                              name="caregiverRelationship"
                                              className="form-control"
                                              placeholder=""
                                              pattern="^[a-zA-Z\-' ]+$"
                                              id="caregiverRelationship"
                                            />
                                            <div className="pdf-text">{formData.caregiverRelationship}</div>
                                          </div>
                                        </div>

                                        {/* row */}
                                        <div className="row mb-3">
                                          <div className="mb-3 col-md-6">
                                            <label
                                              htmlFor="caregiverPhone"
                                              className="form-label"
                                            >
                                              Phone Number
                                            </label>
                                            <Field
                                              name="caregiverPhone"
                                              className="form-control"
                                              placeholder="(123)-456-0000"
                                              pattern="\(?[0-9]{3}\)?-?[0-9]{3}-?[0-9]{4}"
                                              id="caregiverPhone"
                                            />
                                            <div className="pdf-text">{formData.caregiverPhone}</div>
                                          </div>

                                          <div className="mb-3 col-md-6">
                                            <label
                                              htmlFor="caregiverEmail"
                                              className="form-label"
                                            >
                                              Email Address
                                            </label>
                                            <Field
                                              name="caregiverEmail"
                                              className="form-control"
                                              placeholder=""
                                              pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                                              title="Please enter a valid email."
                                              id="caregiverEmail"
                                            />
                                            <div className="pdf-text">{formData.caregiverEmail}</div>
                                          </div>
                                        </div>

                                        {/* row */}
                                        {/* Caregiver Leave Message */}
                                        <div className="row mb-3">
                                          <div
                                            className="mb-3 col-sm-12 col-md-12"
                                            aria-labelledby="caregiverLeaveMessage"
                                          >
                                            <div className="container justify-content-between p-0 m-0">
                                              <div className="row">
                                                <div className="col-sm-12 col-md-9">
                                                  <label
                                                    htmlFor="caregiverLeaveMessage"
                                                    className="form-label"
                                                  >
                                                    Okay to Leave a Message With
                                                    Caregiver?
                                                  </label>
                                                </div>

                                                <div className="col-sm-12 col-md-3 d-flex justify-content-end">
                                                  <Field
                                                    as="select"
                                                    name="caregiverLeaveMessage"
                                                    className="form-control form-select caregiver-leave-message px-2 pt-0 pb-0 "
                                                    placeholder=""
                                                    id="caregiverLeaveMessage"
                                                  >
                                                    <option value="am">
                                                      YES
                                                    </option>
                                                    <option value="pm">
                                                      NO
                                                    </option>
                                                  </Field>
                                                  <div className="downcarret">⌄</div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* <AddCaregiver /> */}
                                      {/* end add caregiver component */}
                                    </div>
                                  )
                                  /* care image */
                                }
                              </div>
                            </div>
                          </div>
                          {/* END PASSED form step */}
                        </div>
                      </div>
                    </div>
                    {/* end form step */}

                    {/* Enrollment footer */}
                    {/* <div className="card-footer"> */}
                    {/* <button className="reverse me-5">
                    <a href="#" className=" nav-link">
                      HI PDF VERSION
                    </a>
                  </button> */}

                    {/* <Back /> */}

                    {/* <Next label="STEP 1 Submit" /> */}
                    {/* <button
                        className=" me-5 p-2"
                        type="submit"
                        >
                        STEP 1 - NEXT
                        </button> */}
                    {/* </div> */}
                    {/* End Enrollment footer */}
                  </StepContent>
                  {/* <div>END CARD CONTAINER CONTENT!!</div> */}
                </CardContainer>
              </>
            ) : null}
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default EnrollStepOne

import React, {useEffect, useState, useMemo, useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import { AppContext } from "../app";
import * as html2canvas from "html2canvas";

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

// styles
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 3,
    borderRadius: 2,
    borderColor: '#b2b2b0',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const focusedStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };
  // end styles
  ///////////////////////////////////////////////////


const FileUpload = ({uploadImage}, props) => {

    // shared context
    const {state, dispatch} = useContext(AppContext);
    // console.log('FileUpload component: Global State/Context: ', state);

  const [files, setFiles] = useState(state.userForm.formData.files);
  const [product, setProduct] = useState(state.userForm.product)
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject} = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf' : [],
    },
    onDrop: acceptedFiles => {
        // console.log("ACCEPTED FILES: ", acceptedFiles);
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      // console.log('@@@@@@@@@ FILE UPLOAD COMPONENT @@@@@@ Dispatch files added....')
      // dispatch({
      //   type: "UPDATE_FILE_UPLOADS",
      //   payload: acceptedFiles,
      // })

    }
  });

  const deleteFile = (file, index) => {
    // console.log(`deleteFile called: target file: ${file.path}, at index: ${index} `)
    const target = file.path;
    const filteredFiles = files.filter(file => file.path !== target);
    // console.log('filteredFiles: ', filteredFiles);
    // console.log('!!!!!!!!!!!!! FILE UPLOAD COMPONENT !!!!!!!!!!! Dispatch files Filtered....')
      setFiles(filteredFiles)
      // Revoke object url if file is being deleted
      URL.revokeObjectURL(file.preview);
  }

  const acceptedFileItems = files.map( (file, index) => (
    <li key={file.path} className="mb-3 d-flex justify-content-between">
      {file.path} - {file.size} bytes {/*- @index: {index} */}
      <button type="button" onClick={ () => deleteFile(file, index) } className="btn btn-danger fs-6 ps-2 pe-2 p-0 text-capitalize">Remove X</button>
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={(e) => {
              // console.log("Renedered style attributes: ", e.target.attributes.style.value);
              const original = e.target.attributes.style.value;
              e.target.attributes.style.value+= "height: 250%;";
              html2canvas(e.target).then(canvas => {
                  const imgData = canvas.toDataURL("image/png");
                  let tmpFiles = [...files];
                  tmpFiles[index].imgData = imgData;
                  setFiles(tmpFiles);
              });
              e.target.attributes.style.value = original;
             // URL.revokeObjectURL(file.preview) ;
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // console.log('FileUpload component: MOUNT: files: ', files);
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    // return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    // files updated
    // console.log('FileUpload component: drop-zone files updated: files: ', files);
    // console.log('@@@@@@@@@ FILE UPLOAD COMPONENT @@@@@@ Dispatch files UPDATED....')
      dispatch({
        type: "UPDATE_FILE_UPLOADS",
        payload: files,
      })

  }, [files]);

  return (
    <section className="container pt-4">

      {files.length > 0 && (
        <div className="row">
          <div className="col-sm-12 col-md-10 accepted-files">
            <div>Files added</div>
            <ul className="">{acceptedFileItems}</ul>
            {/* <h4>Rejected files</h4>
                <ul>{fileRejectionItems}</ul> */}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-sm-12 col-md-10">
          <div
            {...getRootProps({
              style,
              className: "dropzone container rounded-3 border-4",
            })}
          >
            <div className="row">
              <input {...getInputProps()} className="" />
              <div className="col-sm-12 col-md-2">{uploadImage()}</div>
              <div className="col-sm-12 col-md-10">
                <p className="fs-6 fw-bold">
                  Drop your files here or{" "}
                  <span className="text-decoration-underline">browse</span>.
                </p>
                <p className="fs-6">Supports .jpg, .png, .pdf</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-10">
          <aside style={thumbsContainer}>{thumbs}</aside>
        </div>
      </div>

      {/* Dojolvi only */}
      {state.userForm.product === "Dojolvi" && (
        <div className="row mt-1">
          <div className="col-sm-12 col-md-10 fw-medium" style={{marginLeft: "-24px", fontSize: "13px"}}>
            Patient demographic sheet will be uploaded by provider.
          </div>
        </div>
      )}
    </section>
  )
}


export default FileUpload

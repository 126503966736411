import React, { useState, useContext } from "react";
import { AppContext } from "../app";

const PDFButton = () => {
    const { state, dispatch } = useContext(AppContext);
    const [ dropdownState, setDropdownState ] = useState("closed");

    const enrollmentPDF = {
        crysvita: [
            {
                language: "English",
                url: "/pdfs/Crysvita_UltraCare_Enrollment_Form.pdf"
            },
            {
                language: "Español",
                url: "/pdfs/Crysvita_UltraCare_Enrollment_Form_ES.pdf"
            }
        ],
        dojolvi: [
            {
                language: "English",
                url: "/pdfs/DOJOLVI_UltraCare_Enrollment_Form.pdf"
            },
            {
                language: "Español",
                url: "/pdfs/DOJOLVI_UltraCare_Enrollment_Form_ES.pdf"
            }
        ],
        mepsevii: [
            {
                language: "English",
                url: "/pdfs/Mepsevii_UltraCare_Enrollment_Form.pdf"
            }
        ]
    }

    if (enrollmentPDF[state.userForm.product.toLowerCase()].length > 1) {
        return (
            <button
                className={"pdf-download dropdown reverse green me-2 me-sm-5 " + dropdownState}
                onClick={() => {
                    if (dropdownState === "closed") {
                        setDropdownState("opened");
                    }
                    else {
                        setDropdownState("closed");
                    }
                }}
            >
                PDF VERSION
                <ul className="pdf-dropdown">
                    {enrollmentPDF[state.userForm.product.toLowerCase()].map((pdfItem, index) => (
                        <li className="nav-item" key={index}>
                            <a href={pdfItem.url} target="_blank" className=" nav-link">
                                {pdfItem.language}
                            </a>
                        </li>
                    ))}
                </ul>
            </button>
        )
    }
    else {
        return (
            <button className="pdf-download reverse green me-2 me-sm-5">
                <a href={enrollmentPDF[state.userForm.product.toLowerCase()][0].url} target="_blank" className=" nav-link">
                    PDF VERSION
                </a>
            </button>
        )
    }
}

export default PDFButton

import React, { useRef, useEffect, useState } from "react";
import TeamMember from "./teamMember";
import { useUCGQuery } from '../hooks/useUCGQuery';
import { usePALQuery } from '../hooks/usePALQuery';
import { useCELQuery } from '../hooks/useCELQuery';
import { useTeamLeadershipQuery } from '../hooks/useTeamLeadershipQuery';


const TeamSection = ({data, children}) => {
    // console.log(data);
    const ucgData = useUCGQuery().allWpTeamMember.nodes;
    const palData = usePALQuery().allWpTeamMember.nodes;
    const celData = useCELQuery().allWpTeamMember.nodes;
    const leadershipData = useTeamLeadershipQuery().allWpTeamMember.nodes;
    const teamType = data.teamtype;
    // console.log(teamType);

    var teamData;
    switch (teamType) {
        case 'ucg':
            teamData = ucgData;
            break;
        case 'pal':
            teamData = palData;
            break;
        case 'cel':
            teamData = celData;
            break;
        case 'leadership':
            teamData = leadershipData;
            break;
    }
    // console.log(teamData);

    const [ memberState, setMemeberState ] = useState("");

    const teamSectionRef = useRef();

    useEffect(() => {
        // if (typeof window !== "undefined") {
        //     const setBannerOffset = () => {
        //         let topBanner = document.querySelector('header .top-banner');
        //         if (topBanner !== null) {
        //             teamSectionRef.current.style["paddingTop"] = topBanner.offsetHeight - 1  + "px";
        //             teamSectionRef.current.style["marginTop"] = -topBanner.offsetHeight + 1  + "px";
        //
        //             if (teamSectionRef.current.classList.contains('ucg') && window.matchMedia("(max-width: 767px)").matches) {
        //                 teamSectionRef.current.style["paddingTop"] = topBanner.offsetHeight - 1 + 48 + "px";
        //             }
        //         }
        //     }
        //     setTimeout(setBannerOffset, 150);
        //     window.onresize = setBannerOffset;
        // }
    }, []);

    return (
        <section id={"team-" + teamType} className={"team-section " + teamType} ref={teamSectionRef}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="team-section-container">
                            <p className="team-section-header">{teamData[0].tags.nodes[0].name}</p>
                            <h2 className="team-section-title''">{teamData[0].tags.nodes[0].description}</h2>
                            {children}
                            <div className="team-member">
                                {
                                    teamData.map((teamMemberData, i) => (
                                        <TeamMember key={i} data={teamMemberData} memberState={memberState} setMemeberState={setMemeberState}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection;

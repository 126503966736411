import React from "react";
import { useContactImageImageQuery } from '../hooks/useContactImageQuery'
import { findImageByName } from "./findImageByName";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ContactInfo = ({data}) => {
    const contactImageData = useContactImageImageQuery().allFile.edges;
    // console.log('contactImageData: ', contactImageData);


    return (
        <div className={data}>
            <div className="desktop-card">
                <div className="desktop-card-qr">
                    <GatsbyImage image={findImageByName(contactImageData, "addcontact-qr")} alt="QR code" />
                </div>
                <div className="desktop-card-message">
                    <span className="message-title">SAVE OUR CONTACT INFORMATION</span>
                    <span className="message-body">Scan the QR code with your phone's camera</span>
                </div>
            </div>
            <a href="/ultracare_contact.vcf">
                <div className="mobile-card">
                    <div className="mobile-card-icon">
                        <GatsbyImage image={findImageByName(contactImageData, "addcontact-icon")} alt="Icon" />
                    </div>
                    <div className="mobile-card-cta">
                        ADD TO CONTACTS
                    </div>
                </div>
            </a>
        </div>
    )

}

export default ContactInfo;

import React, { useState, useEffect } from "react";
import { AppContext } from "../app";
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName";
import { useAllImageQuery } from '../../hooks/useAllImageQuery'
import CardContainer from "./cardContainer";
import CardContent from "./cardContent";

import Card from "./card"

// Starting step for the enrollment process
const EnrollStart = () => {
    // console.log("EnrollStart component...")

    /////////////////////////////////////////////////////////////
    // shared context
    const {state, dispatch} = React.useContext(AppContext);
    // console.log('EnrollStart component: Global State/Context: ', state);

    const [product, setProduct] = useState(state.userForm.product)
    const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex);

    useEffect(() => {
      // console.log('useEffect: mount/load: EnrollStart Component...');
    }, []);

    // useEffect(() => {
    //   console.log('useEffect: state.userForm.product updated: ', state.userForm.product);
    // }, [state.userForm.product]);

    // useEffect(() => {
    //   console.log('useEffect: product updated: ', product !== "" ? product : "EMPTY - default" );
    // }, [product]);

    return (
      <div className={"enroll-content enroll-start " + state.userForm.product.toLowerCase()}>
        {/* Start component */}
        {/* <div>EnrollStart: product: {product}</div> */}
        {/* <div>EnrollStart: state.userForm.product: {state.userForm.product}</div> */}

        {state.products.map((selectedProduct, index) =>
          product && product === selectedProduct.label ? (
            <div
              className={`tab-pane fade ${
                product && product === selectedProduct.label
                  ? "show active"
                  : ""
              }`}
              id={`pills-${product}`}
              role="tabpanel"
              aria-labelledby={`pills-${product}-tab`}
              key={product+index}
            >
              {/* {selectedProduct.label} - INDEX: {index}  */}
              {/* <Card key={product+index}/> */}

              <CardContainer key={product+index}>
                <CardContent />
              </CardContainer >

            </div>
          ) : null

        )}
      </div>
    )
  }

  export default EnrollStart;

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useFPOImageQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {name: {eq: "fpo"}}) {
        edges {
          node {
            name
            childImageSharp {
              original {
                src
              }
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return data
}

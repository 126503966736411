import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts/layout'
import MainContent from '../components/main-content'

export const query = graphql`
  query ($id: String!, $pagePath: String) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      link
      date
      content
      title
      slug
    }

    config: wpPage(id: { eq: $id }) {
      customConfiguration {
        config
      }
    }

    pageMdx: mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        title
        description
        path
        date
        pageId
        pageConfig {
            pageTitle
            heroImage
        }
      }
      id
      slug
    }

    menu: wpMenu(name: { eq: "mainMenu" }) {
        menuItems {
          nodes {
            label
            url
            parentId
            id
            childItems {
              nodes {
                label
                url
                id
              }
            }
          }
        }
      }
  }
`

const PageTemplate = ({props, data, children}) => {
    return (
        <>
            <Layout slug={data.wpPage.slug}>
                <MainContent data={data} />
            </Layout>
        </>
    );
}

export default PageTemplate;

import React, { useRef, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { findImageByName } from "./findImageByName";
import { useTeamIconQuery } from '../hooks/useTeamIconQuery';
import { useFPOImageQuery } from '../hooks/useFPOImageQuery';

const TeamMember = ({data, memberState, setMemeberState}) => {
        // console.log(data);
        const teamIconData = useTeamIconQuery().allFile.edges;
        const memberIconName = data.tags.nodes[0].slug + "-" + data.teamMemberCustomFields.memberFirstname.toLowerCase();

        const fpoData = useFPOImageQuery().allFile.edges;

        var imageSrc = findImageByName(teamIconData, memberIconName);
        if (typeof imageSrc === "undefined") {
            imageSrc = findImageByName(fpoData, "fpo");
        }

        // Positioning Team Member Detail div
        const detailContainerRef = useRef();
        const [detailContainerStyle, setDetailContainerStyle] = useState({});
        const [teamMemberDetailStyle, setTeamMemberDetailStyle] = useState({});

        const setTeamMemberWidth = () => {
            // console.log(detailContainerRef);
            const detailContainer = detailContainerRef.current;
            const teamMemberContainer = detailContainer.closest('.team-member-container');
            const teamSectionContainer = detailContainer.closest(".team-section-container");
            let leftOffset = -(teamMemberContainer.offsetLeft - teamSectionContainer.offsetLeft) - 16;
            let targetWidth = teamSectionContainer.offsetWidth;

            // console.log("leftOffset: " + leftOffset);
            // console.log("width: " + teamSectionContainer.offsetWidth);

            // console.log("is md down: ", (typeof window !== "undefined" && window.matchMedia('(max-width: 767px)').matches) );
            if (typeof window !== "undefined" && window.matchMedia('(max-width: 767px)').matches) {
                leftOffset = leftOffset - 12;
                targetWidth = targetWidth + 24;
            }

            setDetailContainerStyle({
                left: leftOffset+"px",
                width: targetWidth+"px"
            });
        }

        const setTeamMemberHeight = () => {
            const detailContainer = detailContainerRef.current;
            // console.log(detailContainerRef);
            // console.log("height: " + detailContainer.offsetHeight);

            setTeamMemberDetailStyle({
                height: detailContainer.offsetHeight + 22 + "px"
            });

            if (typeof window !== "undefined" && window.matchMedia('(max-width: 767px)').matches) {
                const scrollArea = detailContainer.querySelector(".member-detail-flex");
                // console.log("scrollArea: ", scrollArea);
                // console.log(scrollArea.scrollTop);
                scrollArea.scrollTop = -1000;
            }
        }

        // Click Handler
        const onMemberClick = () => {
            if (memberState === memberIconName) {
                setMemeberState("");
            }
            else {
                setMemeberState(memberIconName);
                setTimeout(()=>{
                    setTeamMemberWidth();
                    setTeamMemberHeight();
                }, 30)
            }
        }

        return (
            <div className={`team-member-container ${memberState === memberIconName ? "active" : ""}`}>
                <div className="team-member-icon" onClick={onMemberClick}>
                    <div className="member-icon">
                        <GatsbyImage image={imageSrc} alt={"Photo of " + data.teamMemberCustomFields.memberFirstname + " " + data.teamMemberCustomFields.memberLastname}/>
                    </div>
                    <div className="member-icon-name">
                        <p>{data.teamMemberCustomFields.memberFirstname + (data.tags.nodes[0].slug === "leadership" ? " " + data.teamMemberCustomFields.memberLastname : "")}</p>
                    </div>
                    <div className="member-title">
                        <p>{typeof data.teamMemberCustomFields.memberTitle !== "undefined" ? data.teamMemberCustomFields.memberTitle : ""}</p>
                    </div>
                </div>
                <div className={"team-member-detail " + data.tags.nodes[0].slug} style={teamMemberDetailStyle}>
                    <div className="team-member-detail-container" ref={detailContainerRef} style={detailContainerStyle}>
                        <div className="member-detail-close" onClick={() => {setMemeberState("");}}></div>
                        <div className="member-detail-flex">
                            <div className="member-detail-desc">
                                <p className="member-team-type">{data.tags.nodes[0].name}</p>
                                <h3 className="member-name">{data.teamMemberCustomFields.memberFirstname + " " + data.teamMemberCustomFields.memberLastname}</h3>
                                <p className="member-bios">{data.teamMemberCustomFields.memberBios}</p>
                                <p className="member-quote">{data.teamMemberCustomFields.memberQuote}</p>
                            </div>
                            <div className="member-detail-icon">
                                <GatsbyImage image={imageSrc} alt={"Photo of " + data.teamMemberCustomFields.memberFirstname + " " + data.teamMemberCustomFields.memberLastname}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default TeamMember;

import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../app";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

import FileUpload from "./fileUpload";
import Card from "./card"
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName";
import { useAllImageQuery } from '../../hooks/useAllImageQuery'
import CardContainer from "./cardContainer";
import CardContent from "./cardContent";
import StepContent from "./stepContent";
import Back from "./back";
import Next from "./next";
// import * as html2canvas from "html2canvas";


import { BsFillStarFill } from "react-icons/bs";


const EnrollStepTwo = () => {
  /////////////////////////////////////////////////////////////
  // shared context
  const { state, dispatch } = useContext(AppContext)
  // console.log("EnrollStepTwo component: Global State/Context: ", state)

  const [product, setProduct] = useState(state.userForm.product)
  const [files, setFiles] = useState(state.userForm.formData.files)
  const [formData, setFormData] = useState(state.userForm.formData)
  const [filesUploaded, setFilesUploaded] = useState(files.length > 0)
  const [hasInsurance, setHasInsurance] = useState(formData.has_insurance ? formData.has_insurance : "")
  const [activeStepIndex, setActiveStepIndex] = useState(
    state.userForm.activeStepIndex
  )

  const paperImageData = useAllImageQuery().allFile.edges // get all image data
  const paperImageName = `papers@2x` // name of image
  // console.log("paperImageName: ", paperImageName)

  // small upload image TODO: replace with small grey version
  const papersUploadImageData = useAllImageQuery().allFile.edges // get all image data
  const papersUploadImageName = `papers-header-grey@2x` // name of image
  // console.log("papersUploadImageName: ", papersUploadImageName)

  const uploadImage = () => {
    return (
      /* paper image */
      <GatsbyImage
      image={findImageByName(
        papersUploadImageData,
        papersUploadImageName
      )}
      alt={`paper upload image`}
      className={"papersUploadImage"}
    />
    )
  }

  // TODO: Move to shared
  const _handleFormSubmit = () => {
    // console.log("_handleFormSubmit called...")
  }
  // TODO: move to shared
  // toggle show caregiver
  const useToggle = (initialState) => {
    const [isToggled, setIsToggled] = React.useState(initialState)

    // put [setIsToggled] into the useCallback's dependencies array
    // this value never changes so the callback is not going to be ever re-created
    const toggle = React.useCallback(
      () => setIsToggled((state) => !state),
      [setIsToggled]
    )

    return [isToggled, toggle]
  }
  const [isToggled, toggle] = useToggle(false)

  const renderError = (message) => (
    <p className="error text-danger">{message}</p>
  )

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
  // End move to shared

  const hasInsuranceChanged = (e) => {
      setHasInsurance(e.target.value)
  }

  const ValidationSchema = yup.object().shape({
    has_insurance: yup.string().required("Please check one of the choices below."),
    files: yup.array(),
  })

  useEffect(() => {
    // console.log("useEffect: mount/load: EnrollStepTwo Component...")
  }, [])

  useEffect(() => {
    // console.log(`useEffect: state updated: `, state)
  }, [state]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(`useEffect: activeStepIndex updated: `)
  }, [activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log(
    //   "useEffect: state.userForm.product updated: ",
    //   state.userForm.product !== "" ? state.userForm.product : "EMPTY - default"
    // )
  }, [state.userForm.product])

  useEffect(() => {
    // console.log(
    //   "useEffect: product updated: ",
    //   product !== "" ? product : "EMPTY - default"
    // )
  }, [product])

  useEffect(() => {
    // console.log(
    //   "useEffect: files updated: ",
    //   files !== "" ? files : "Files currently EMPTY - default"
    // )
  }, [files])

  useEffect(() => {
      setFilesUploaded(state.userForm.formData.files.length > 0);
  }, [state.userForm.formData.files])

  return (
    <div className={"enroll-content enroll-step-two " + state.userForm.product.toLowerCase()}>
      {/* <div>EnrollStepTwo</div>
    <div>activeStepIndex: {activeStepIndex}</div>
    <div>state.userForm.activeStepIndex: {state.userForm.activeStepIndex}</div> */}
      <Formik
        initialValues={{
          has_insurance: formData.has_insurance ? formData.has_insurance : "",
          // file upload
          files: formData.files ? formData.files : [],
          files_uploaded: formData.files_uploaded ? formData.files_uploaded : false
        }}
        // enableReinitialize
        validationSchema={ValidationSchema}
        // validator={() => ({})}
        onSubmit={async (values) => {
          if (hasInsurance === "yes" && !filesUploaded) {
              return;
          }


          const data = { ...formData, ...values }
          // const data = { ...formData, ...values, files: state.userForm.formData.files}

          dispatch({
              type: "UPDATE_PDF_UPLOADED_IMAGE",
              payload: state.userForm.formData.files.map(file => file.imgData)
          })

          // console.log("Enroll Step 2: onSubmit: values: ", values)

          // see data
          // await sleep(500)
          // alert(JSON.stringify(values, null, 2))
          setFormData(data)

          // const input = document.querySelector("#pills-tabContent .enroll-content");
          // input.classList.add("generating-pdf")
          // html2canvas(input).then(canvas => {
          //     const imgData = canvas.toDataURL("image/png");
          //     dispatch({
          //         type: "UPDATE_PDF_IMAGE_STEP_TWO",
          //         payload: imgData
          //     })
          //     input.classList.remove("generating-pdf")

              state.userForm.formData.files.forEach(file => {
                  URL.revokeObjectURL(file.preview)
              });
          // });

          dispatch({
            type: "UPDATE_FORM_DATA",
            payload: data,
          })
          setActiveStepIndex(activeStepIndex + 1)

          dispatch({
            type: "UPDATE_STEP",
            payload: activeStepIndex + 1,
          })

          dispatch({
              type: "UPDATE_FILES_UPLOADED",
              payload: filesUploaded
          })
        }}
      >
        {({validateField}) => (
        <Form className="enrollment-form">
          <div
            className={`tab-pane fade ${product && "show active"}`}
            id={`pills-${product}`}
            role="tabpanel"
            aria-labelledby={`pills-${product}-tab`}
            key={product + activeStepIndex}
          >
            {activeStepIndex !== 0 ? (
              <>
                {/* <div>WE ARE STEP ONE!! - {state.userForm.product}</div> */}
                <CardContainer key={product + activeStepIndex}>
                  {/* <div>BEGIN CARD CONTAINER CONTENT!!</div> */}
                  <StepContent>
                    {/* form step */}
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-8 left-content step-two">
                          {/* Main Form Content area */}

                          <div className="about-you mb-3">
                            Next, let’s get your insurance information
                          </div>

                          <div className="next-steps">
                            <BsFillStarFill className="starIcon" />
                            Be sure to provide copies (front and back) of
                            patient <strong>
                              MEDICAL and PRESCRIPTION
                            </strong>{" "}
                            insurance cards.
                          </div>

                          <ErrorMessage
                            name="has_insurance"
                            render={renderError}
                          />

                          <div className="container">
                            <div className="row mt-4">
                              <div className="offset-sm-1 col-sm-10 form-check">
                              <label className="form-check-label">
                                <Field
                                  type="radio"
                                  name="has_insurance"
                                  value="no"
                                  onClick={e => hasInsuranceChanged(e)}
                                  className="check me-2 form-check-input"
                                />
                                  Patient does not have health insurance
                                </label>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="offset-sm-1 col-sm-10 form-check">
                              <label className="form-check-label">
                                <Field
                                  type="radio"
                                  name="has_insurance"
                                  value="yes"
                                  onClick={e => hasInsuranceChanged(e)}
                                  className="check me-2 form-check-input"
                                />
                                  Provide copies of all medical and prescription
                                  insurance cards—front and back (primary and
                                  secondary, supplemental coverage)
                                </label>
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="mb-3 offset-sm-1 col-sm-10">
                                  <div style={{paddingLeft: "15px"}} className={hasInsurance !== 'yes' || (hasInsurance === 'yes' && filesUploaded) ? "d-none" : ""}>
                                      <p className="error text-danger">Please provide copies (front and back) of patient insurance card.</p>
                                  </div>
                                {/* file upload */}
                                <FileUpload uploadImage={uploadImage} />
                              </div>
                            </div>
                          </div>

                          {/* END MAIN FORM Content area */}
                        </div>

                        {/* SIDE CONTENT START */}
                        <div
                          className={`col-12 col-lg-4 p-4 ${
                            state.userForm.product &&
                            state.userForm.product != ""
                              ? String(state.userForm.product.toLowerCase()) +
                                "-side-bg-gradient"
                              : ""
                          }`}
                        >
                          {/* SIDE CONTENT - PASSED form step */}
                          {/* make component */}
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12 p-0 mt-lg-5 text-center paper-image">
                                {/* side */}
                                {activeStepIndex === 2 ? (
                                  /* paper image */
                                  <GatsbyImage
                                    image={findImageByName(
                                      paperImageData,
                                      paperImageName
                                    )}
                                    alt={`paper logo`}
                                    className={"paperImage"}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {/* END PASSED form step */}
                        </div>
                      </div>
                    </div>
                    {/* end form step */}

                    {/* Enrollment footer */}
                    {/* <div className="card-footer"> */}
                    {/* <button className="reverse me-5">
                <a href="#" className=" nav-link">
                  HI PDF VERSION
                </a>
              </button> */}

                    {/* <Back /> */}

                    {/* <Next label="STEP 1 Submit" /> */}
                    {/* <button
                    className=" me-5 p-2"
                    type="submit"
                    >
                    STEP 1 - NEXT
                    </button> */}
                    {/* </div> */}
                    {/* End Enrollment footer */}
                  </StepContent>
                  {/* <div>END CARD CONTAINER CONTENT!!</div> */}
                </CardContainer>
              </>
            ) : null}
          </div>
        </Form>
        )}
      </Formik>
    </div>
  )
}

export default EnrollStepTwo

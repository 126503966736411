import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../app";
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "../findImageByName";
import { useAllImageQuery } from '../../hooks/useAllImageQuery'
import Stepper from "./stepper";
import Back from "./back";
import Next from "./next";

// TODO: Cleanup
// Creates a Card content section
const CardContent = () => {

  // console.log("CardContent component...");
  const { state, dispatch } = useContext(AppContext);

  // initial local state
  const [product, setProduct] = useState(state.userForm.product);
  const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex);

  const logoImageData = useAllImageQuery().allFile.edges; // get all image data
  const logoImageName = String(state.userForm.product.toLowerCase()) + `-logo@2x`; // name of image
  // console.log("logoImageName: ", logoImageName);

  const logoAltText = {
      crysvita: "CRYSVITA (burosumab-twza) logo",
      dojolvi: "DOJOLVI (triheptanoin) logo",
      mepsevii: "Mepsevii (vestronidase alfa-vjbk) logo"
  }

  return (
    <>
      {/* dynamic className for gradient */}
      {/* card body */}
      <div
        className={`card-body ${
          state.userForm.product && state.userForm.product != ""
            ? String(state.userForm.product.toLowerCase()) + "-bg-gradient"
            : ""
        }`}
      >
        {/* CARD CONTENT!!! */}
        <div className="container">
          <div className="row">
            <div className="col col-sm-12 text-center">
              <h5 className={`card-title`}>
                You’re about to begin the {state.userForm.product.toUpperCase()} UltraCare{" "}
                <nobr>Start Form:</nobr>{" "}
              </h5>
            </div>
          </div>
        </div>

        {/* WHERE FORM LIVES */}
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 offset-md-1 text-md-center logo">
              {/* logo */}
              <GatsbyImage
                image={findImageByName(logoImageData, logoImageName)}
                alt={logoAltText[state.userForm.product.toLowerCase()]}
              />
            </div>
            <div
              className={`col-12 col-md-6 to-be-completed ${
                product && product != ""
                  ? String(state.userForm.product.toLowerCase())
                  : ""
              }`}
            >
              <h5
                className={`to-be-completed ${
                  product && product != ""
                    ? String(state.userForm.product.toLowerCase())
                    : ""
                }`}
              >
                This digital form is to be completed with your healthcare
                provider.
              </h5>
              <p className="mt-3">
                Have this information ready to help your healthcare provider
                complete the form:
              </p>
              <ul>
                <li>Caregiver information</li>
                <li>
                  Medical and prescription insurance cards{" "}
                  <span className="fw-normal small">
                    (front and back; PDF, PNG, or JPEG format)
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row d-flex align-items-end complete-form">
            <div className="col-sm-6 offset-sm-1 p-0 ps-2">
              Please note that a completed form is required for your enrollment.
            </div>
            <div className="col-sm-5"></div>
          </div>
        </div>
        {/* END WHERE FORM LIVES */}
      </div>
      {/* end card body */}
    </>
  )
}

  export default CardContent;

import React, { useState, useEffect } from "react"
import { navigate } from "gatsby";
import { AppContext } from "./app";
import { GatsbyImage } from "gatsby-plugin-image"
import { findImageByName } from "./findImageByName"
import { useAllImageQuery } from '../hooks/useAllImageQuery'
import Card from "./forms/card"
import Step from "./forms/step"
import Stepper from "./forms/stepper"
import { isBrowser, showTabs } from "./utils/utils"

// TODO Cleanup

const Enrollment = () => {
  // shared context
  const { state, dispatch } = React.useContext(AppContext)

  // Check if window is defined (in browser or in node.js).
  // console.log('Check if browser: isBrowser: ', isBrowser() );
  if( isBrowser() ){
    // console.log('Current page/pathname: ', window.location.pathname );
  }
  const tabsVisible = showTabs();
  // console.log('tabsVisible: ', tabsVisible );

  /////////////////////////////////////////////////////////////
  // initial local state
  const [product, setProduct] = useState(state.userForm.product)

  const [formData, setFormData] = useState(state.userForm.formData)

  const [activeStepIndex, setActiveStepIndex] = useState(state.userForm.activeStepIndex)
  // console.log("IN ENROLLMENT COMPONENT: activeStepIndex: ", activeStepIndex)

  useEffect(() => {
    // console.log(`useEffect: activeStepIndex updated: `, activeStepIndex)
  }, [activeStepIndex]) // Only re-run the effect if activeStepIndex changes

  useEffect(() => {
    // console.log("useEffect: state.userForm.activeStepIndex updated: ", state.userForm.activeStepIndex)
  }, [state.userForm.activeStepIndex])

  useEffect(() => {
    // console.log("useEffect: mount/load: Enrollment Component...")
    // console.log('Do tabs check and set product if needed...')
    if (!tabsVisible) {
      switch (window.location.pathname) {
        case '/dojolvi/':
          handleUpdateProduct("Dojolvi")
          break
        case '/mepsevii/':
          handleUpdateProduct("Mepsevii")
          break
        default:
          break
      }
    }
  }, [])

  useEffect(() => {
    // console.log("useEffect: state updated: Enrollment Component...", state)
  }, [state])

  useEffect(() => {
    // console.log(
    //   "useEffect: state.userForm.product updated: ",
    //   state.userForm.product !== "" ? state.userForm.product : "EMPTY - default"
    // )
  }, [state.userForm.product])

  useEffect(() => {
    // console.log(
    //   "useEffect: product updated: ",
    //   product !== "" ? product : "EMPTY - default"
    // )
  }, [product])

  const handleUpdateProduct = (product) => {
    // console.log("Updating Product: ", product);
    setProduct(product);
    dispatch({
      type: "UPDATE_PRODUCT",
      payload: product
    })
  }

  const imageData = useAllImageQuery().allFile.edges // get all image data
  const dojolviProductPillImage = "dojolvi-box@2x" // name of image
  const mepseviiProductPillImage = "mepsevbox@2x" // name of image
  const dojolviProductPillActiveImage = "dojolvi-box-active@2x" // name of image
  const mepseviiProductPillActiveImage = "mepsevbox-active@2x" // name of image

  const scrollToContent = () => {
      if (isBrowser && window.matchMedia("(max-width: 767px)").matches) {
          let enrollContent = document.querySelector('#pills-tabContent');
          let topBanner = document.querySelector('header .top-banner');
          if (enrollContent !== null && topBanner !== null) {
              enrollContent.style["paddingTop"] = topBanner.offsetHeight - 1  + "px";
              enrollContent.style["marginTop"] = -topBanner.offsetHeight + 1  + "px";
          }
          navigate("/#pills-tabContent");
      }
  }

  return (
    <>
      <div id="" className="enroll container">
        <div className="row">
          <div className="col-sm-12">
            {/* <div> ENROLLMENT COMPONENT: activeStepIndex: {activeStepIndex}</div>
            <div>
              {" "}
              ENROLLMENT COMPONENT: state.userForm.activeStepIndex:{" "}
              {state.userForm.activeStepIndex}
            </div> */}

            {tabsVisible ? (
              <ul
                className="nav nav-pills mb-3 flex-column flex-sm-row"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item col-12 col-md-4" role="presentation">
                  <button
                    onClick={() => {
                      handleUpdateProduct("Dojolvi");
                      setProduct("Dojolvi");
                      scrollToContent();
                    }}
                    className="nav-link"
                    id="pills-dojolvi-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-dojolvi"
                    type="button"
                    role="tab"
                    aria-controls="pills-dojolvi"
                    aria-selected="false"
                  >
                    <GatsbyImage
                      image={findImageByName(
                        imageData,
                        dojolviProductPillImage
                      )}
                      alt={"DOJOLVI (triheptanoin) Start Form logo"}
                      className="inactive-tab-logo"
                    />
                    <GatsbyImage
                      image={findImageByName(
                        imageData,
                        dojolviProductPillActiveImage
                      )}
                      alt={"DOJOLVI (triheptanoin) Start Form logo"}
                      className="active-tab-logo"
                    />
                    {/* Dojolvi */}
                  </button>
                </li>
                <li className="nav-item col-12 col-md-4" role="presentation">
                  <button
                    onClick={() => {
                      handleUpdateProduct("Mepsevii");
                      setProduct("Mepsevii");
                      scrollToContent();
                    }}
                    className="nav-link"
                    id="pills-mepsevii-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-mepsevii"
                    type="button"
                    role="tab"
                    aria-controls="pills-mepsevii"
                    aria-selected="false"
                  >
                    <GatsbyImage
                      image={findImageByName(
                        imageData,
                        mepseviiProductPillImage
                      )}
                      alt={"Mepsevii (vestronidase alfa-vjbk) Start Form logo"}
                      className="inactive-tab-logo"
                    />
                    <GatsbyImage
                      image={findImageByName(
                        imageData,
                        mepseviiProductPillActiveImage
                      )}
                      alt={"Mepsevii (vestronidase alfa-vjbk) Start Form logo"}
                      className="active-tab-logo"
                    />
                    {/* Mepsevii */}
                  </button>
                </li>
              </ul>
            ) : null}

            {product && product != "" ? (
              /* Tab content */
              <div
                className={`tab-content ${
                  state.userForm.activeStepIndex > 0 ? " step-up" : ""
                } ${tabsVisible ? "" : "no-tabs"}`}
                id="pills-tabContent"
              >
                {/* handles steps */}
                {/* <Stepper /> */}
                <Step />
                <iframe id="pdf-generator" width="1" height="1" src="/pdf-generator/pdf-generator.html" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
};

export default Enrollment;

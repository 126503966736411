import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useTeamIconQuery = () => {
const data = useStaticQuery(graphql`
    {
        allFile(filter: {name: {regex: "/^(leadership|ucg|pal|cel)-/"}}) {
            edges {
                node {
                    name
                    childImageSharp {
                        original {
                            src
                        }
                        gatsbyImageData
                    }
                }
            }
        }
    }
    `)
    return data
}
